/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { Link, useHistory } from 'react-router-dom';

import Swal from "sweetalert2";

import PropTypes from 'prop-types';

const Newformador = ({ permissaoAdicionar }) => {

  const history = useHistory();

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user } = AuthUser();
  const [name, setName] = useState('');
  //eslint-disable-next-line no-unused-vars
  const [telefone, setTelefone] = useState('');
  const [data_nasc, setDataNasc] = useState(new Date());
  const [genero, setGenero] = useState(1);
  const [endereco, setEndereco] = useState('');
  const [habilidades, setHabilidade] = useState('');
  //eslint-disable-next-line no-unused-vars
  const [nivel_id, setNivel] = useState(0);
  const [tipo, setTipo] = useState(1);
  const [niveis, setNiveis] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [nivelSelecionado, setNivelSelecionado] = useState(0);

  const [situacao, setSituacao] = useState(1);
  const [disponibilidade_data, setDisponibilidadeData] = useState();
  const [disponibilidade_hora1, setDisponibilidadeHora1] = useState();
  const [disponibilidade_hora2, setDisponibilidadeHora2] = useState();

  const [localizacaoSelecionada, setLocalizacaoSelecionada] = useState(null);
  const [localizacoes, setLocalizacoes] = useState([]);

  const [titulo_academico1, setTACA1] = useState(1);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [fotografia, setSelectedFile] = useState(null);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);


  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const navegarParaLista = () => {
    history.push('/dream-pos/users/formadorlists');
  };

  const navegarParaEdicao = (id) => {
    history.push('/dream-pos/users/newformadoredit/' + id);
  };

  useEffect(() => {
    setTipo(2); //Tipo formador
    getNiveis();
    getPaises();
  }, []);

  const getPaises = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const resultado2 = await http.get('/paises');

      setLocalizacoes(resultado2.data.regioes);

      setLocalizacaoSelecionada(resultado2?.data?.regioes[0]);

      setLoading(false);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getNiveis = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/niveis');

      console.log("Carregado...");
      console.log(res.data);

      setNiveis(res.data.niveis);

      setNivel(res.data.niveis[0].id);


      setNivelSelecionado(res.data.niveis[0].id);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleFileChange = (e) => {
    // Lógica para processar a imagem, se necessário
    setSelectedFile(e.target.files[0]);

    // Restante do código
  };

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {
    if (permissaoAdicionar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (name.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (!validateEmail(email)) {
          setError('O email deve ser válido.');
          toggleLoading(false);

          // Show a error toast
          toast.error('O email deve ser válido.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (password.length < 6) {
          setError('A senha deve ter pelo menos seis caracteres.');
          toggleLoading(false);

          // Show a error toast
          toast.error('A senha deve ter pelo menos seis caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (password !== password2) {
          setError('As senhas não coincidem.');
          toggleLoading(false);

          // Show a error toast
          toast.error('As senhas não coincidem.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        await uploadUtilizador();


      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const uploadUtilizador = async () => {
    if (permissaoAdicionar) {
      try {

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('nivel_id', nivel_id);
        formData.append('tipo', tipo);
        formData.append('telefone', telefone);
        formData.append('fotografia', fotografia);

        formData.append('data_nasc', data_nasc);
        formData.append('genero', genero);
        formData.append('titulo_academico1', titulo_academico1);
        formData.append('endereco', endereco);
        formData.append('habilidades', habilidades);

        formData.append('nacionalidade_id', localizacaoSelecionada);
        formData.append('disponibilidade_data', disponibilidade_data);
        formData.append('disponibilidade_hora1', disponibilidade_hora1);
        formData.append('disponibilidade_hora2', disponibilidade_hora2);
        formData.append('situacao', situacao);

        formData.append('estado', 0);
        formData.append('empresa_id', user.empresa_id);

        const response = await http.post('/register', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        // Show a success toast
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            // Redirecionar para a lista de utilizadores após o cadastro bem-sucedido
            //history.push('/dream-pos/users/userlists');

            setName('');
            setEmail('');
            setTelefone('');
            setPassword('');
            setPassword2('');
            setSelectedFile(null);

            if (!permanecerNaPagina) {
              navegarParaLista();
            } else {
              navegarParaEdicao(response.data.data.id);
            }
          },
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown1);
  };


  const handleSelectSituacaoChange = (id) => {
    setSituacao(id);
  };

  const handleSelectLocalizacaoChange = (id) => {

    setLocalizacaoSelecionada(id);

  };

  const renderOptionsLocalizacoes = (regions) => {
    return regions?.map((region) => (
      <React.Fragment key={region?.id}>
        <option value={region?.id}>{region?.nome}</option>
        {region?.children && region?.children.length > 0 && renderOptionsLocalizacoes(region?.children)}
      </React.Fragment>
    ));
  };


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-users border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de candidatos</h4>
              <h6>Adicionar candidatos</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/users/formadorlists" className="btn btn-added">
              <i className="fa fa-table"></i>&nbsp; Lista de candidatos
            </Link>

          </div>
        </div>


        <div className="card">
          <div className="card-body">


            <div className="row">

              <div className="col-md-12">
                <div className="card bg-white">
                  <div className="card-header">
                  </div>
                  <div className="card-body">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          href="#basictab1"
                          data-bs-toggle="tab"
                        >
                          Dados Pessoais
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#formacaoacademica"
                          data-bs-toggle="tab"
                        >
                          Formação académica
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#expprofissional"
                          data-bs-toggle="tab"
                        >
                          Experiência Profissional
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#habilidade"
                          data-bs-toggle="tab"
                        >
                          Habilidades e Competências
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#anexos"
                          data-bs-toggle="tab"
                        >
                          Anexos
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane show active" id="basictab1">




                        <div className="row my-4">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="form-group">
                              <label>Nome de utilizador</label>
                              <input type="text" value={name} onChange={e => setName(e.target.value)} />
                            </div>
                            <div className="form-group">
                              <label>E-mail</label>
                              <input type="text" value={email} onChange={e => setEmail(e.target.value)} />
                            </div>
                            <div className="form-group">
                              <label>Senha</label>
                              <div className="pass-group">
                                <input
                                  type={passwordShown ? "text" : "password"}
                                  className=" pass-input" value={password} onChange={e => setPassword(e.target.value)}
                                />
                                <span
                                  className={`fa toggle-password ${passwordShown ? "fa-eye" : "fa-eye-slash"
                                    }`}
                                  onClick={togglePassword}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label>Genero</label>

                              <select className="form-control select" onChange={e => setGenero(e.target.value)} >
                                <option value="2">
                                  Feminino
                                </option>
                                <option value="1" >
                                  Masculino
                                </option>
                              </select>

                            </div>

                            <div className="form-group">
                              <label>Data Nascimento</label>
                              <input type="date" className="form-control" value={data_nasc} onChange={e => setDataNasc(e.target.value)} />
                            </div>


                            <div className="form-group">
                              <label>Situação</label>

                              <select className="form-control select" onChange={(e) => handleSelectSituacaoChange(e.target.value)}
                                value={situacao} >
                                <option value="1"  >
                                  Candidato Submetido
                                </option>
                                <option value="2">
                                  Currículo Avaliado
                                </option>
                                <option value="3">
                                  Contato Feito
                                </option>
                                <option value="4">
                                  Reunião Agendada
                                </option>
                                <option value="5">
                                  Entrevistado - Primeira Etapa
                                </option>
                                <option value="6">
                                  Entrevistado - Segunda Etapa
                                </option>
                                <option value="7">
                                  Avaliação Técnica
                                </option>
                                <option value="8">
                                  Referências Verificadas
                                </option>
                                <option value="9">
                                  Oferta Enviada
                                </option>
                                <option value="10">
                                  Oferta Aceita
                                </option>
                                <option value="11">
                                  Contratado
                                </option>
                                <option value="12">
                                  Recusado
                                </option>

                              </select>

                            </div>


                            <div className="form-group">
                              <label>Data de disponibilidade</label>
                              <input type="date" name="disponibilidade_data" className="form-control" value={disponibilidade_data} onChange={e => setDisponibilidadeData(e.target.value)} />
                            </div>



                            <div className="form-group">
                              <label>Habilidades</label>
                              <textarea className="form-control" value={habilidades} onChange={e => setHabilidade(e.target.value)}></textarea>
                            </div>

                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="form-group">
                              <label>Telefone</label>
                              <input type="text" value={telefone} onChange={e => setTelefone(e.target.value)} />
                            </div>
                            <div className="form-group">
                              <label>Nivel</label>

                              <select onChange={(e) => setNivelSelecionado(e.target.value)} className="form-control select" value={nivelSelecionado}>
                                {niveis?.map((cdata) => (
                                  <option key={cdata.id} value={cdata.id}>
                                    {cdata.designacao}
                                  </option>
                                ))}
                              </select>

                            </div>
                            <div className="form-group">
                              <label>Confirmar senha</label>
                              <div className="pass-group">
                                <input
                                  type={passwordShown1 ? "text" : "password"}
                                  className=" pass-input" value={password2} onChange={e => setPassword2(e.target.value)}
                                />
                                <span
                                  className={`fa toggle-password ${passwordShown1 ? "fa-eye" : "fa-eye-slash"
                                    }`}
                                  onClick={togglePassword1}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label>Nacionalidade</label>

                              <select
                                name="nacionalidade_id"
                                onChange={(e) => handleSelectLocalizacaoChange(e.target.value)}
                                value={localizacaoSelecionada}
                                className="form-control select"
                              >
                                {renderOptionsLocalizacoes(localizacoes)}
                              </select>

                            </div>

                            <div className="form-group">
                              <label>Nivel Académico</label>

                              <select className="form-control" value={titulo_academico1} onChange={e => setTACA1(e.target.value)}>
                                <option value="1">Técnico médio</option>
                                <option value="2">Bacharel</option>
                                <option value="3">Licenciatura</option>
                                <option value="4">Doutoramento</option>
                              </select>


                            </div>

                            <div className="form-group">
                              <label>Disponibilidade Hora entrada</label>
                              <input type="time" name="disponibilidade_hora1" className="form-control" value={disponibilidade_hora1} onChange={e => setDisponibilidadeHora1(e.target.value)} />
                            </div>

                            <div className="form-group">
                              <label>Disponibilidade Hora saida</label>
                              <input type="time" name="disponibilidade_hora2" className="form-control" value={disponibilidade_hora2} onChange={e => setDisponibilidadeHora2(e.target.value)} />
                            </div>


                            <div className="form-group">
                              <label>Endereço</label>
                              <input type="text" value={endereco} onChange={e => setEndereco(e.target.value)} />
                            </div>

                            <div className="form-group">
                              {/* Checkbox "Permanecer na Página" */}
                              <label>
                                <input
                                  type="checkbox"
                                  checked={permanecerNaPagina}
                                  onChange={handlePermanecerNaPagina}
                                />
                                &nbsp; Permanecer na Página depois de salvar
                              </label>
                            </div>
                          </div>
                          {/* Restante do código */}
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="form-group">
                              <label>Fotografia</label>
                              <div className="image-upload image-upload-new">
                                <input type="file" onChange={handleFileChange} />
                                <div className="image-uploads">
                                  {fotografia ? (
                                    <>
                                      <img
                                        src={URL.createObjectURL(fotografia)}
                                        alt="Pré-visualização"
                                      />
                                      <h4>Carregar fotografia</h4>
                                    </>
                                  ) : (
                                    <h4>Carregar fotografia</h4>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Restante do código */}
                          <div className="col-lg-12">
                            <a onClick={submitForm} className="btn btn-submit me-2">
                              {loading ? <Spinner /> : 'Salvar'}
                            </a>
                            <a onClick={navegarParaLista} className="btn btn-cancel">
                              Cancelar
                            </a>
                          </div>
                        </div>




                      </div>
                      <div className="tab-pane" id="basictab2">
                        Tab content 2
                      </div>
                      <div className="tab-pane" id="basictab3">
                        Tab content 3
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>
        </div>


      </div>

      <ToastContainer />

    </div>
  );
};

Newformador.propTypes = {
  permissaoAdicionar: PropTypes.bool.isRequired,
};

export default Newformador;
