/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";
import { Link, useHistory } from 'react-router-dom';
import Swal from "sweetalert2";
import PropTypes from 'prop-types';

const Novaturma = ({ permissaoAdicionar }) => {

  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const [dataIni, setDataIni] = useState();
  const [dataFim, setDataFim] = useState();
  const [hora1, setHora1] = useState('09:00:00');
  const [hora2, setHora2] = useState('13:00:00');
  const [preco, setPreco] = useState();
  const [obs, setObs] = useState(0);
  const [local, setLocal] = useState(0);

  const [galeria, setGaleria] = useState(1);
  const [curso_selecionado, setCursoSelecionado] = useState(1);
  const [sala_selecionada, setSalaSelecionada] = useState(1);
  const [periodo_selecionado, setPeriodoSelecionado] = useState(1);
  const [sabado_selecionado, setSabadoSelecionado] = useState(1);
  const [entrega_selecionado, setEntregaSelecionada] = useState(1);
  const [status_selecionado, setStatusSelecionado] = useState(1);
  const [promocao_selecionada, setPromocaoSelecionada] = useState(1);
  const [pagamento_selecionado, setPagamentoSelecionado] = useState(1);

  const [loading, setLoading] = useState(false);

  const [imagemPreview, setimagemPreview] = useState(null);
  const [imagem, setSelectedFile] = useState(null);
  const [imagem3, setImagem3] = useState(null);

  const [cursos, setCursos] = useState([]);
  const [salas, setSalas] = useState([]);


  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push('/dream-pos/turma/turmalista');
  };

  const navegarParaEdicao = (id) => {
    history.push('/dream-pos/turma/turmaedit/' + id);
  };


  useEffect(() => {
    getturma();

  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };


  const getturma = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res1 = await http.get('/salas_ativas');
      setSalas(res1.data.salas);

      const res2 = await http.get('/cursos_ativos');
      setCursos(res2.data.cursos);

      setCursoSelecionado('');

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleSelectGaleriaChange = (valor) => {
    setGaleria(valor);
  }

  const handleSelectCursoChange = (valor) => {

    setCursoSelecionado(valor);

    // Encontre o objeto correspondente com base no ID
    const selectedObject = cursos?.find(obj => obj.id === parseInt(valor, 10));

    // Se o objeto for encontrado, defina os valores dos campos de entrada
    if (selectedObject) {
      setPreco(selectedObject.preco);
      setimagemPreview(selectedObject.imagem);
      setImagem3(selectedObject.imagem);
    }

  }

  const handleSelectSalaChange = (valor) => {
    setSalaSelecionada(valor);
  }

  const handleSelectPeriodoChange = (valor) => {
    setPeriodoSelecionado(valor);
  }

  const handleSelectSabadoChange = (valor) => {
    setSabadoSelecionado(valor);
  }

  const handleSelectEntregaChange = (valor) => {
    setEntregaSelecionada(valor);
  }

  const handleSelectStatusChange = (valor) => {
    setStatusSelecionado(valor);
  }

  const handleSelectPromocaoChange = (valor) => {
    setPromocaoSelecionada(valor);
  }

  const handleSelectPagamentoChange = (valor) => {
    setPagamentoSelecionado(valor);
  }

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem').src = newFileURL;

    // Restante do código

  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (permissaoAdicionar) {

      try {
        toggleLoading(true);


        await updateturma();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {

          console.log("Erro:");
          console.log(error);

          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }



  };

  const updateturma = async () => {
    try {
      const formData = new FormData();

      formData.append('dataIni', dataIni);
      formData.append('dataFim', dataFim);
      formData.append('hora1', hora1);
      formData.append('hora2', hora2);
      formData.append('local', local);
      formData.append('obs', obs);

      formData.append('curso_id', curso_selecionado);
      formData.append('sala_id', sala_selecionada);

      formData.append('periodo', periodo_selecionado);
      formData.append('sabados', sabado_selecionado);
      formData.append('modo', entrega_selecionado);
      formData.append('status', status_selecionado);
      formData.append('promocao', promocao_selecionada);
      formData.append('paga_coletivo', pagamento_selecionado);

      formData.append('preco', preco);
      formData.append('galeria', galeria);
      formData.append('imagem', imagem);
      formData.append('imagem3', imagem3);

      const response = await http.post('/turma/register', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log(response);

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de turmas após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          } else {
            navegarParaEdicao(response.data.data.id);
          }
        }
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const renderOptioncursos = (cursos) => {
    return cursos?.map((curso) => (
      <React.Fragment key={curso.id}>
        <option value={curso.id}>{curso.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionsalas = (salas) => {
    return salas?.map((sala) => (
      <React.Fragment key={sala.id}>
        <option value={sala.id}>{sala.designacao}</option>
      </React.Fragment>
    ));
  };



  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-book border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de registo de turmas</h4>
              <h6>Nova turma</h6>
            </div>
          </div>
          <div className="page-btn">

            <Link to="/dream-pos/turma/turmalista" className="btn btn-added">
              <i className="fa fa-table"></i>&nbsp;
              Lista de turmas
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Curso</label>

                    <select
                      onChange={(e) => handleSelectCursoChange(e.target.value)}
                      value={curso_selecionado}
                      className="form-control select"
                    >
                      <option value="">Escolha o curso</option>
                      {renderOptioncursos(cursos)}

                    </select>

                  </div>

                  <div className="form-group">
                    <label>Data Inicio</label>
                    <input type="date" name="dataIni" id="dataIni" className="form-control" value={dataIni} onChange={e => setDataIni(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label>Hora inicio</label>
                    <input type="time" name="hora1" id="hora1" className="form-control" value={hora1} onChange={e => setHora1(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label>Preço ( Kz )</label>
                    <input id="preco" name="preco" type="text" value={preco} onChange={e => setPreco(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label>Aos Sábados</label>

                    <select className="form-control select" onChange={e => handleSelectSabadoChange(e.target.value)} value={sabado_selecionado}>

                      <option value="1">
                        Não
                      </option>
                      <option value="2">
                        Sim
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Estado</label>

                    <select className="form-control select" onChange={e => handleSelectStatusChange(e.target.value)} value={status_selecionado}>

                      <option value="1" >
                        Anulada
                      </option>
                      <option value="2" >
                        Em inscrição
                      </option>
                      <option value="3">
                        A decorrer
                      </option>
                      <option value="4">
                        Encerrada
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Local</label>
                    <textarea className="form-control" name="local" value={local} onChange={e => setLocal(e.target.value)}></textarea>
                  </div>

                  <div className="form-group">
                    <label>Pagamento coletivo</label>

                    <select className="form-control select" onChange={e => handleSelectPagamentoChange(e.target.value)} value={pagamento_selecionado} >
                      <option value="1">
                        Não
                      </option>
                      <option value="2" >
                        Sim
                      </option>

                    </select>

                  </div>

                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>


                </div>

                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Sala</label>

                    <select
                      onChange={(e) => handleSelectSalaChange(e.target.value)}
                      value={sala_selecionada}
                      className="form-control select"
                    >
                      {renderOptionsalas(salas)}
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Data Fim</label>
                    <input id="dataFim" name="dataFim" type="date" className="form-control" value={dataFim} onChange={e => setDataFim(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label>Hora fim</label>
                    <input type="time" name="hora2" id="hora2" className="form-control" value={hora2} onChange={e => setHora2(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label>Periodo</label>

                    <select className="form-control select" onChange={e => handleSelectPeriodoChange(e.target.value)} value={periodo_selecionado}>

                      <option value="1" >
                        Manhã
                      </option>
                      <option value="2" >
                        Tarde
                      </option>
                      <option value="3">
                        Noite
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Modo entrega</label>

                    <select className="form-control select" onChange={e => handleSelectEntregaChange(e.target.value)} value={entrega_selecionado}>

                      <option value="1" >
                        Presencial
                      </option>
                      <option value="2">
                        Online
                      </option>
                      <option value="3">
                        Hibrido
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Em Promoção</label>

                    <select className="form-control select" onChange={e => handleSelectPromocaoChange(e.target.value)} value={promocao_selecionada}>

                      <option value="1" >
                        Não
                      </option>
                      <option value="2">
                        Sim
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Observação</label>
                    <textarea className="form-control" name="obs" value={obs} onChange={e => setObs(e.target.value)}></textarea>
                  </div>

                  <div className="form-group">
                    <label>No slide do website ?</label>

                    <select className="form-control select" onChange={e => handleSelectGaleriaChange(e.target.value)} value={galeria}>

                      <option value="1">
                        Aparece
                      </option>
                      <option value="2">
                        Não aparece
                      </option>
                    </select>

                  </div>


                </div>


                {/* Restante do código */}



                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Imagem do curso</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" name="imagem" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img id="imagem"
                              src={imagemPreview ? url_root + "/storage/" + imagemPreview : URL.createObjectURL(imagem)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}

                      </div>
                    </div>
                  </div>

                </div>

                {/* Restante do código */}

                <div className="col-lg-4">

                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>

                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>



                </div>




              </div>
            </form>

          </div>
        </div>
        {/* /add */}


      </div>

      <ToastContainer />

    </div>
  );
};

Novaturma.propTypes = {
  permissaoAdicionar: PropTypes.bool.isRequired
};

export default Novaturma;
