/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { Link, useHistory, useParams } from 'react-router-dom';

import Swal from "sweetalert2";

import PropTypes from 'prop-types';

const NewformadorEdit = ({ permissaoAtivar, permissaoAtualizar }) => {

  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root } = AuthUser();

  const [nivelSelecionado, setNivelSelecionado] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const [utilizador, setUtilizador] = useState(null);
  const [loading, setLoading] = useState(false);
  const [niveis, setNiveis] = useState([]);
  const [genero, setGenero] = useState(1);
  const [situacao, setSituacao] = useState();

  const [localizacaoSelecionada, setLocalizacaoSelecionada] = useState(null);
  const [localizacoes, setLocalizacoes] = useState([]);


  const [fotografia, setSelectedFile] = useState(null);
  const [fotografiaPreview, setFotografiaPreview] = useState(null);

  const [doc_bilhete, setSelectedFileBilhete] = useState(null);
  const [doc_cv, setSelectedFileCV] = useState(null);
  const [doc_academico, setSelectedFileAcademico] = useState(null);
  const [doc_certificacoes, setSelectedFileCertificacao] = useState(null);

  const [titulo_academico1, setTACA1] = useState(1);
  const [titulo_academico2, setTACA2] = useState(2);
  const [titulo_academico3, setTACA3] = useState(3);

  const [inputs, setInputs] = useState([]);

  /* TABELA MODAL formacoes */

  const [funcao, setfuncao] = useState([]);
  const [funcaos, setfuncaos] = useState([]);

  // State to track whether the "checkgeral" checkbox is checked
  const [checkGeral, setCheckGeral] = useState(false);
  const [funcaosSelecionados, setfuncaosSelecionados] = useState([]);

  // Function to handle the "checkgeral" checkbox change
  const handleCheckGeralChange = () => {
    // Toggle the checked state
    setCheckGeral(!checkGeral);

    // Get all the "check-funcao" checkboxes
    const checkboxes = document.querySelectorAll(".check-funcao");

    // Create a new array to store the selected collaborator IDs
    const selectedfuncaos = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !checkGeral;
      if (checkbox.checked) {
        // Add the ID to the selectedfuncaos array
        selectedfuncaos.push(checkbox.dataset.id);
      }
    });

    // Update the state with the selected collaborator IDs
    setfuncaosSelecionados(selectedfuncaos);

  };

  // Function to handle the "checkgeral" checkbox change
  const handleCheckGeralChange2 = () => {
    // Toggle the checked state
    setCheckGeral(!checkGeral);

    // Get all the "check-funcao" checkboxes
    const checkboxes = document.querySelectorAll(".check-colaborador");

    // Create a new array to store the selected collaborator IDs
    const selectedfuncaos = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !checkGeral;
      if (checkbox.checked) {
        // Add the ID to the selectedfuncaos array
        selectedfuncaos.push(checkbox.dataset.id);
      }
    });

    // Update the state with the selected collaborator IDs
    setfuncaosSelecionados(selectedfuncaos);

  };

  // Function to handle the "checkBoxes from table" checkbox change
  const handleCheckBoxChange = () => {

    // Get all the "check-funcao" checkboxes
    const checkboxes = document.querySelectorAll(".check-funcao");

    // Create a new array to store the selected collaborator IDs
    const selectedfuncaos = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {

      if (checkbox.checked) {
        // Add the ID to the selectedfuncaos array
        const funcaoId = checkbox.dataset.id;
        console.log('funcao ID selecionado:', funcaoId);
        selectedfuncaos.push(funcaoId);
      }
    });

    // Update the state with the selected collaborator IDs
    setfuncaosSelecionados(selectedfuncaos);

  }

  // Function to handle the "checkBoxes from table" checkbox change
  const handleCheckBoxChange2 = () => {

    // Get all the "check-funcao" checkboxes
    const checkboxes = document.querySelectorAll(".check-colaborador");

    // Create a new array to store the selected collaborator IDs
    const selectedfuncaos = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {

      if (checkbox.checked) {
        // Add the ID to the selectedfuncaos array
        const funcaoId = checkbox.dataset.id;
        console.log('funcao ID selecionado:', funcaoId);
        selectedfuncaos.push(funcaoId);
      }
    });

    // Update the state with the selected collaborator IDs
    setfuncaosSelecionados(selectedfuncaos);

  }

  const vincularFormadorfuncao = async () => {

    if (permissaoAtualizar) {
      // Exibe a mensagem de confirmação


      if ((funcaos?.length >= 3) || (funcaos?.length + funcaosSelecionados?.length) > 3) {
        toast.error('O número máximo de funções são 3', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } else
        if (funcaosSelecionados.length > 0) {

          Swal.fire({
            title: "Tem certeza ?",
            text: "Pretende associar as funções selecionadas ao candidato [ " + inputs?.name + " ] ?.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, associar!",
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                // Envie uma solicitação para a API para eliminar os usuários selecionados
                const response = await http.post('/candidato/funcaos/associar', {
                  funcaosSelecionados, id
                });

                getfuncaos();

                if (response.data.message)
                  toast.success(response.data.message, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
                else
                  toast.error(response.data.error, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });

              } catch (error) {

                console.error(error);

                toast.error('Ocorreu um erro ao associar formacoes ao funcao. Por favor, tente novamente mais tarde.', {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });

              }
            }
          });

        } else {

          toast.error('Tem de selecionar pelo menos um funcao', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const desvincularFormadorfuncao = async () => {

    if (permissaoAtualizar) {
      // Exibe a mensagem de confirmação

      if (funcaosSelecionados.length > 0) {

        Swal.fire({
          title: "Tem certeza ?",
          text: "Pretende Desassociar as funções selecionadas ao candidato [ " + inputs?.name + " ] ?.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, associar!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Envie uma solicitação para a API para eliminar os usuários selecionados
              const response = await http.post('/candidato/funcaos/desassociar', {
                funcaosSelecionados, id
              });

              getfuncaos();

              toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } catch (error) {
              console.error(error);

              toast.error('Ocorreu um erro ao associar formacoes ao funcao. Por favor, tente novamente mais tarde.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          }
        });

      } else {

        toast.error('Tem de selecionar pelo menos uma função', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const getfuncaos = async () => {
    try {

      //setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/funcaos/candidato/' + id);

      setfuncaos(res.data.funcaos);

    } catch (error) {

      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

    } finally {
      //setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };


  const triggerFileChangeDocBilhete = () => {

    const element = document.querySelector('#input-doc-bilhete');
    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }
    // Restante do código
  };

  const handleFileChangeBilhete = (e) => {
    // Lógica para processar a imagem, se necessário
    setSelectedFileBilhete(e.target.files[0]);

    // Restante do código
  };


  const triggerFileChangeDocCV = () => {

    const element = document.querySelector('#input-doc-cv');
    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }
    // Restante do código
  };

  const handleFileChangeCV = (e) => {
    // Lógica para processar a imagem, se necessário
    setSelectedFileCV(e.target.files[0]);

    // Restante do código
  };

  const triggerFileChangeDocAcademico = () => {

    const element = document.querySelector('#input-doc-academico');
    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }
    // Restante do código
  };


  const handleFileChangeAcademico = (e) => {
    // Lógica para processar a imagem, se necessário
    setSelectedFileAcademico(e.target.files[0]);

    // Restante do código
  };

  const triggerFileChangeDocCertificacao = () => {

    const element = document.querySelector('#input-doc-certificacao');
    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }
    // Restante do código
  };

  const handleFileChangeCertificacao = (e) => {
    // Lógica para processar a imagem, se necessário
    setSelectedFileCertificacao(e.target.files[0]);

    // Restante do código
  };

  /* FIM CONSTANTES TABELA MODAL formacoes */

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const getNiveis = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/niveis');

      console.log("Carregado...");
      console.log(res.data);

      setNiveis(res.data.niveis);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const navegarParaLista = () => {
    history.push('/dream-pos/users/formadorlists');
  };

  const irParafuncao = (id) => {

    const element = document.querySelector('.btn-close');

    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }

    history.push('/dream-pos/sala/salaedit/' + id);

  }

  useEffect(() => {

    const fetchData = async () => {
      try {

        console.log('Fetching niveis...');
        await getNiveis();
        console.log('Niveis:', niveis);

        console.log('Fetching user data...');
        const userResponse = await http.get('/utilizador/' + id);
        console.log('User data:', userResponse.data.utilizador);

        setUtilizador(userResponse.data.utilizador);

        if (userResponse.data.utilizador?.tipo == 2) { 

          setInputs(userResponse.data.utilizador);
          setNivelSelecionado(userResponse.data.utilizador.nivel_id);
          setGenero(userResponse.data.utilizador.genero);
          setFotografiaPreview(userResponse.data.utilizador.fotografia);

          setSituacao(userResponse.data.utilizador.situacao);

          setTACA1(userResponse.data.utilizador.titulo_academico1);
          setTACA2(userResponse.data.utilizador.titulo_academico2);
          setTACA3(userResponse.data.utilizador.titulo_academico3);

          setLocalizacaoSelecionada(userResponse.data.utilizador.nacionalidade_id);

          const resultado = await http.get('/funcaos_ativas');
          setfuncao(resultado.data.funcaos);

          getfuncaos();

          /* Outras regiões */

          setLoading(true); // Set loading to true when the request starts

          const resultado2 = await http.get('/paises');

          setLocalizacoes(resultado2.data.regioes);

          setLoading(false);

          /* Outras regiões */

        }

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

  }, [id]);


  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const handleResetSenha = (id) => {

    if (permissaoAtualizar) {

      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer resetar a senha?",
        text: "Isso irá resetar a senha do usuário.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, resetar senha!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação para resetar a senha
          resetarSenha(id);
        }
      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const resetarSenha = async (id) => {

    if (permissaoAtualizar) {
      try {
        // Gere uma senha aleatória no cliente
        const senhaAleatoria = Math.random().toString(36).slice(-8);

        // Exiba a senha antes de enviar a solicitação
        alert(`Nova Senha: ${senhaAleatoria}`);

        // Envie uma solicitação para a API para resetar a senha
        const response = await http.post(`/resetar-senha/${id}`, { novaSenha: senhaAleatoria });

        // Exiba uma mensagem de sucesso
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });


      } catch (error) {
        // Exiba uma mensagem de erro se ocorrer um problema
        toast.error('Ocorreu um erro ao resetar a senha. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const getUtilizador = async () => {
    try {
      /* */
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizador/' + id);

      console.log("Carregado...");
      console.log(res.data);

      setInputs(res.data.utilizador);

      setNivelSelecionado(res.data.utilizador.nivel_id);

      setFotografiaPreview(res.data.utilizador.fotografia);

      console.log('User :');
      console.log(res.data.utilizador);

      console.log('Accao_id :');
      console.log(nivelSelecionado);

      //getPermissoes();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {
    if (permissaoAtivar) {
      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/utilizador/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getUtilizador();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de utilizadores após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
      nivel_id: name === 'nivel_id' ? value : prevInputs.nivel_id,
    }));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('fotografia').src = newFileURL;

    // Restante do código
  };

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (permissaoAtualizar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs?.name?.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (!validateEmail(inputs.email)) {
          setError('O email deve ser válido.');
          toggleLoading(false);

          // Show a error toast
          toast.error('O email deve ser válido.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await updateUtilizador();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const updateUtilizador = async () => {
    try {

      const formData = new FormData();
      formData.append('id', id);

      formData.append('name', inputs.name);
      formData.append('email', inputs.email);
      formData.append('telefone', inputs.telefone);
      formData.append('genero', genero);
      formData.append('nif', inputs.nif);
      formData.append('nivelo_id', nivelSelecionado);

      if (fotografia)
        formData.append('fotografia', fotografia);

      formData.append('data_nasc', inputs.data_nasc);

      formData.append('endereco', inputs.endereco);
      formData.append('habilidades', inputs.habilidades);

      formData.append('nacionalidade_id', localizacaoSelecionada);

      formData.append('disponibilidade_data', inputs.disponibilidade_data);
      formData.append('disponibilidade_hora1', inputs.disponibilidade_hora1);
      formData.append('disponibilidade_hora2', inputs.disponibilidade_hora2);

      formData.append('titulo_academico1', titulo_academico1);
      formData.append('titulo_academico2', titulo_academico2);
      formData.append('titulo_academico3', titulo_academico3);

      formData.append('titulo_descricao1', inputs.titulo_descricao1);
      formData.append('titulo_descricao2', inputs.titulo_descricao2);
      formData.append('titulo_descricao3', inputs.titulo_descricao3);

      formData.append('ano_formatura1', inputs.ano_formatura1);
      formData.append('ano_formatura2', inputs.ano_formatura2);
      formData.append('ano_formatura3', inputs.ano_formatura3);

      formData.append('instituicao1', inputs.instituicao1);
      formData.append('instituicao2', inputs.instituicao2);
      formData.append('instituicao3', inputs.instituicao3);

      formData.append('exp_empresa1', inputs.exp_empresa1);
      formData.append('exp_empresa2', inputs.exp_empresa2);
      formData.append('exp_empresa3', inputs.exp_empresa3);

      formData.append('exp_cargo1', inputs.exp_cargo1);
      formData.append('exp_cargo2', inputs.exp_cargo2);
      formData.append('exp_cargo3', inputs.exp_cargo3);

      formData.append('exp_responsabilidade1', inputs.exp_responsabilidade1);
      formData.append('exp_responsabilidade2', inputs.exp_responsabilidade2);
      formData.append('exp_responsabilidade3', inputs.exp_responsabilidade3);

      formData.append('exp_ano1', inputs.exp_ano1);
      formData.append('exp_ano2', inputs.exp_ano2);
      formData.append('exp_ano3', inputs.exp_ano3);

      formData.append('competencias', inputs.competencias);
      formData.append('certificacoes', inputs.certificacoes);

      if (doc_bilhete)
        formData.append('doc_bilhete', doc_bilhete);

      if (doc_cv)
        formData.append('doc_cv', doc_cv);

      if (doc_academico)
        formData.append('doc_academico', doc_academico);

      if (doc_certificacoes)
        formData.append('doc_certificacoes', doc_certificacoes);

      formData.append('genero', genero);
      formData.append('nacionalidade_id', localizacaoSelecionada);
      formData.append('situacao', situacao);

      const response = await http.post('/update/candidato', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de utilizadores após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          }
        }
      });

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };


  const handleSelectGeneroChange = (id) => {
    setGenero(id);
  };

  const handleSelectSituacaoChange = (id) => {
    setSituacao(id);
  };

  const handleSelectLocalizacaoChange = (id) => {

    setLocalizacaoSelecionada(id);

  };

  const renderOptionsLocalizacoes = (regions) => {
    return regions?.map((region) => (
      <React.Fragment key={region?.id}>
        <option value={region?.id}>{region?.nome}</option>
        {region?.children && region?.children.length > 0 && renderOptionsLocalizacoes(region?.children)}
      </React.Fragment>
    ));
  };




  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-users border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de candidato</h4>
              <h6>Editar candidato</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/users/newformador" className="btn btn-added">
              <i className="fa fa-plus"></i>&nbsp; Add Novo Candidato
            </Link>

          </div>
        </div>
        {/* /add */}


        {/* Inicio funcaos do formador */}

        <div className="card">
          <div className="card-body">


            <div className="row mt-3">

              <div className="col-md-12">
                <div className="card bg-white">
                  <div className="card-header">
                  </div>
                  <div className="card-body">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          href="#dadospessoais"
                          data-bs-toggle="tab"
                        >
                          Dados Pessoais
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#formacaoacademica"
                          data-bs-toggle="tab"
                        >
                          Formação académica
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#expprofissional"
                          data-bs-toggle="tab"
                        >
                          Experiência Profissional
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#habilidade"
                          data-bs-toggle="tab"
                        >
                          Habilidades e Competências
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#anexos"
                          data-bs-toggle="tab"
                        >
                          Anexos
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">

                      <div className="tab-pane show active" id="dadospessoais">


                        <div className="row mt-3">

                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="form-group">
                              <label>Nome do candidato</label>
                              <input type="text" name="name" id="nome" value={inputs?.name} onChange={handleInputsValueChanged} />
                            </div>
                            <div className="form-group">
                              <label>E-mail</label>
                              <input id="email" name="email" type="text" value={inputs.email} onChange={handleInputsValueChanged} />
                            </div>

                            <div className="form-group">
                              <label>Genero</label>

                              <select className="form-control select" onChange={e => handleSelectGeneroChange(e.target.value)} value={genero} >
                                <option value="2" selected={inputs.genero == 1}>
                                  Feminino
                                </option>
                                <option value="1" selected={inputs.genero == 2}>
                                  Masculino
                                </option>
                              </select>

                            </div>

                            <div className="form-group">
                              <label>Data Nascimento</label>
                              <input type="date" name="data_nasc" className="form-control" value={inputs.data_nasc} onChange={handleInputsValueChanged} />
                            </div>


                            <div className="form-group">
                              <label>Situação</label>

                              <select className="form-control select" onChange={(e) => handleSelectSituacaoChange(e.target.value)}
                                value={situacao} >
                                <option value="1" selected={inputs.situacao == 1}>
                                  Candidato Submetido
                                </option>
                                <option value="2" selected={inputs.situacao == 2}>
                                  Currículo Avaliado
                                </option>
                                <option value="3" selected={inputs.situacao == 3}>
                                  Contato Feito
                                </option>
                                <option value="4" selected={inputs.situacao == 4}>
                                  Reunião Agendada
                                </option>
                                <option value="5" selected={inputs.situacao == 5}>
                                  Entrevistado - Primeira Etapa
                                </option>
                                <option value="6" selected={inputs.situacao == 6}>
                                  Entrevistado - Segunda Etapa
                                </option>
                                <option value="7" selected={inputs.situacao == 7}>
                                  Avaliação Técnica
                                </option>
                                <option value="8" selected={inputs.situacao == 8}>
                                  Referências Verificadas
                                </option>
                                <option value="9" selected={inputs.situacao == 9}>
                                  Oferta Enviada
                                </option>
                                <option value="10" selected={inputs.situacao == 10}>
                                  Oferta Aceita
                                </option>
                                <option value="11" selected={inputs.situacao == 11}>
                                  Contratado
                                </option>
                                <option value="12" selected={inputs.situacao == 12}>
                                  Recusado
                                </option>

                              </select>

                            </div>


                            <div className="form-group">
                              <label>Data de disponibilidade</label>
                              <input type="date" name="disponibilidade_data" className="form-control" value={inputs.disponibilidade_data} onChange={handleInputsValueChanged} />
                            </div>

                            <div className="form-group">
                              <label>Endereço</label>
                              <input type="text" name="endereco" value={inputs.endereco} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="form-group">
                              <label>Telefone</label>
                              <input id="telefone" name="telefone" type="text" value={inputs.telefone} onChange={handleInputsValueChanged} />
                            </div>
                            <div className="form-group">
                              <label>Nivel</label>

                              <select onChange={(e) => setNivelSelecionado(e.target.value)}
                                value={nivelSelecionado} className="form-control select">
                                {niveis?.map((cdata) => (
                                  <option key={cdata.id} value={cdata.id}>
                                    {cdata.designacao}
                                  </option>
                                ))}
                              </select>

                            </div>

                            <div className="form-group">
                              <label>NIF</label>
                              <input type="text" name="nif" value={inputs.nif} onChange={handleInputsValueChanged} />
                            </div>



                            <div className="form-group">
                              <label>Nacionalidade</label>

                              <select
                                name="nacionalidade_id"
                                onChange={(e) => handleSelectLocalizacaoChange(e.target.value)}
                                value={localizacaoSelecionada}
                                className="form-control select"
                              >
                                {renderOptionsLocalizacoes(localizacoes)}
                              </select>

                            </div>

                            <div className="form-group">
                              <label>Nivel Académico</label>

                              <select className="form-control" value={titulo_academico1} onChange={e => setTACA1(e.target.value)}>
                                <option value="1">Técnico médio</option>
                                <option value="2">Bacharel</option>
                                <option value="3">Licenciatura</option>
                                <option value="4">Doutoramento</option>
                              </select>



                            </div>

                            <div className="form-group">
                              <label>Disponibilidade Hora entrada</label>
                              <input type="time" name="disponibilidade_hora1" className="form-control" value={inputs.disponibilidade_hora1} onChange={handleInputsValueChanged} />
                            </div>

                            <div className="form-group">
                              <label>Disponibilidade Hora saida</label>
                              <input type="time" name="disponibilidade_hora2" className="form-control" value={inputs.disponibilidade_hora2} onChange={handleInputsValueChanged} />
                            </div>

                           

                          </div>

                          {/* Restante do código */}
                          <div className="col-lg-4 col-sm-6 col-12">

                            <div className="form-group">
                              <a
                                href="#"
                                className="btn btn-secondary"
                                data-bs-toggle="modal"
                                data-bs-target="#add_funcao">

                                <i className="fa fa-list"></i> Associar funções

                              </a>
                            </div>

                            <div className="form-group">
                              <label>Fotografia</label>
                              <div className="image-upload image-upload-new">
                                <input type="file" onChange={handleFileChange} />
                                <div className="image-uploads">
                                  {fotografiaPreview ? (
                                    <>
                                      <img id="fotografia"
                                        src={fotografiaPreview ? url_root + "/storage/" + fotografiaPreview : URL.createObjectURL(fotografia)}
                                        alt="Pré-visualização"
                                      />
                                      <h4>Carregar fotografia</h4>
                                    </>
                                  ) : (

                                    <>
                                      <img id="fotografia"
                                        alt="Pré-visualização"
                                      />
                                      <h4>Carregar fotografia</h4>
                                    </>

                                  )}
                                </div>
                              </div>
                            </div>


                            <div className="form-group">
                              <label>Ativar / Desativar</label>

                              <div className="status-toggle d-flex justify-content-between align-items-center">
                                <input
                                  type="checkbox"
                                  id={`user${id}`}
                                  className="check"
                                  defaultChecked={inputs.estado}
                                  onChange={() => handleAtivarDesativar(id, !inputs.estado)}
                                  name={`estado_${id}`}
                                />
                                <label htmlFor={`user${id}`} className="checktoggle">

                                </label>
                              </div>

                            </div>
                            <div className="form-group">
                              <label>Resetar senha</label>

                              <a className="" onClick={() => handleResetSenha(id)}>
                                <i className="fa fa-key"></i>
                              </a>

                            </div>

                            <div className="form-group">
                              {/* Checkbox "Permanecer na Página" */}
                              <label>
                                <input
                                  type="checkbox"
                                  checked={permanecerNaPagina}
                                  onChange={handlePermanecerNaPagina}
                                />
                                &nbsp; Permanecer na Página depois de salvar
                              </label>
                            </div>


                          </div>



                          {/* Restante do código */}

                          {
                            utilizador?.tipo == 2 ? (<>
                            </>) : (<></>)
                          }

                        </div>

                      </div>

                      <div className="tab-pane show" id="formacaoacademica">

                        <div className="row mt-3">
                          <div className="col-lg-2 col-sm-2 col-2">

                            <div className="form-group">
                              <label>Nivel Académico [ 1 ]</label>

                              <select className="form-control" value={titulo_academico1} onChange={e => setTACA1(e.target.value)}>
                                <option value="1">Técnico médio</option>
                                <option value="2">Bacharel</option>
                                <option value="3">Licenciatura</option>
                                <option value="4">Doutoramento</option>
                              </select>
                            </div>

                          </div>
                          <div className="col-lg-8 col-sm-8 col-8">

                            <div className="form-group">
                              <label>Descrição da formação</label>
                              <input type="text" name="titulo_descricao1" className="form-control" value={inputs.titulo_descricao1} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-2 col-sm-2 col-2">

                            <div className="form-group">
                              <label>Data de termino</label>
                              <input type="date" name="ano_formatura1" className="form-control" value={inputs.ano_formatura1} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-12 col-sm-12 col-12">

                            <div className="form-group">
                              <label>Instituição da formação</label>
                              <input type="text" name="instituicao1" className="form-control" value={inputs.instituicao1} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-lg-2 col-sm-2 col-2">

                            <div className="form-group">
                              <label>Nivel Académico [ 2 ]</label>

                              <select className="form-control" value={titulo_academico2} onChange={e => setTACA2(e.target.value)}>
                                <option value="1">Técnico médio</option>
                                <option value="2">Bacharel</option>
                                <option value="3">Licenciatura</option>
                                <option value="4">Doutoramento</option>
                              </select>
                            </div>

                          </div>
                          <div className="col-lg-8 col-sm-8 col-8">

                            <div className="form-group">
                              <label>Descrição da formação</label>
                              <input type="text" name="titulo_descricao2" className="form-control" value={inputs.titulo_descricao2} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-2 col-sm-2 col-2">

                            <div className="form-group">
                              <label>Data de termino</label>
                              <input type="date" name="ano_formatura2" className="form-control" value={inputs.ano_formatura2} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-12 col-sm-12 col-12">

                            <div className="form-group">
                              <label>Instituição da formação</label>
                              <input type="text" name="instituicao2" className="form-control" value={inputs.instituicao2} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-lg-2 col-sm-2 col-2">

                            <div className="form-group">
                              <label>Nivel Académico [ 3 ]</label>

                              <select className="form-control" value={titulo_academico3} onChange={e => setTACA3(e.target.value)}>
                                <option value="1">Técnico médio</option>
                                <option value="2">Bacharel</option>
                                <option value="3">Licenciatura</option>
                                <option value="4">Doutoramento</option>
                              </select>
                            </div>

                          </div>
                          <div className="col-lg-8 col-sm-8 col-8">

                            <div className="form-group">
                              <label>Descrição da formação</label>
                              <input type="text" name="titulo_descricao3" className="form-control" value={inputs.titulo_descricao3} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-2 col-sm-2 col-2">

                            <div className="form-group">
                              <label>Data de termino</label>
                              <input type="date" name="ano_formatura3" className="form-control" value={inputs.ano_formatura3} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-12 col-sm-12 col-12">

                            <div className="form-group">
                              <label>Instituição da formação</label>
                              <input type="text" name="instituicao3" className="form-control" value={inputs.instituicao3} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                        </div>

                      </div>

                      <div className="tab-pane show" id="expprofissional">

                        <div className="row mt-3">
                          <div className="col-lg-6 col-sm-6 col-6">

                            <div className="form-group">
                              <label>Empresa [ 1 ]</label>
                              <input type="text" name="exp_empresa1" className="form-control" value={inputs.exp_empresa1} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-6 col-sm-6 col-6">

                            <div className="form-group">
                              <label>Função que desempenhou</label>
                              <input type="text" name="exp_cargo1" className="form-control" value={inputs.exp_cargo1} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-10 col-sm-10 col-10">

                            <div className="form-group">
                              <label>Descrição das responsabilidades</label>
                              <textarea name="exp_responsabilidade1" className="form-control" value={inputs.exp_responsabilidade1} onChange={handleInputsValueChanged}></textarea>
                            </div>

                          </div>
                          <div className="col-lg-2 col-sm-2 col-2">

                            <div className="form-group">
                              <label>Fim vinculo contratual</label>
                              <input type="date" name="exp_ano1" className="form-control" value={inputs.exp_ano1} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-lg-6 col-sm-6 col-6">

                            <div className="form-group">
                              <label>Empresa [ 2 ]</label>
                              <input type="text" name="exp_empresa2" className="form-control" value={inputs.exp_empresa2} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-6 col-sm-6 col-6">

                            <div className="form-group">
                              <label>Função que desempenhou</label>
                              <input type="text" name="exp_cargo2" className="form-control" value={inputs.exp_cargo2} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-10 col-sm-10 col-10">

                            <div className="form-group">
                              <label>Descrição das responsabilidades</label>
                              <textarea name="exp_responsabilidade2" className="form-control" value={inputs.exp_responsabilidade2} onChange={handleInputsValueChanged}></textarea>
                            </div>

                          </div>
                          <div className="col-lg-2 col-sm-2 col-2">

                            <div className="form-group">
                              <label>Fim vinculo contratual</label>
                              <input type="date" name="exp_ano2" className="form-control" value={inputs.exp_ano2} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-lg-6 col-sm-6 col-6">

                            <div className="form-group">
                              <label>Empresa [ 3 ]</label>
                              <input type="text" name="exp_empresa3" className="form-control" value={inputs.exp_empresa3} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-6 col-sm-6 col-6">

                            <div className="form-group">
                              <label>Função que desempenhou</label>
                              <input type="text" name="exp_cargo3" className="form-control" value={inputs.exp_cargo3} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                          <div className="col-lg-10 col-sm-10 col-10">

                            <div className="form-group">
                              <label>Descrição das responsabilidades</label>
                              <textarea name="exp_responsabilidade3" className="form-control" value={inputs.exp_responsabilidade3} onChange={handleInputsValueChanged}></textarea>
                            </div>

                          </div>
                          <div className="col-lg-2 col-sm-2 col-2">

                            <div className="form-group">
                              <label>Fim vinculo contratual</label>
                              <input type="date" name="exp_ano3" className="form-control" value={inputs.exp_ano3} onChange={handleInputsValueChanged} />
                            </div>

                          </div>
                        </div>

                      </div>

                      <div className="tab-pane show" id="habilidade">

                        <div className="row mt-3">

                          <div className="col-lg-12 col-sm-12 col-12">

                            <div className="form-group">
                              <label>Habilidades</label>
                              <textarea name="habilidades" className="form-control" value={inputs.habilidades} onChange={handleInputsValueChanged}></textarea>
                            </div>

                          </div>


                          <div className="col-lg-12 col-sm-12 col-12">

                            <div className="form-group">
                              <label>Competências</label>
                              <textarea name="competencias" className="form-control" value={inputs.competencias} onChange={handleInputsValueChanged}></textarea>
                            </div>

                          </div>


                          <div className="col-lg-12 col-sm-12 col-12">

                            <div className="form-group">
                              <label>Certificações</label>
                              <textarea name="certificacoes" className="form-control" value={inputs.certificacoes} onChange={handleInputsValueChanged}></textarea>
                            </div>

                          </div>


                        </div>


                      </div>

                      <div className="tab-pane show" id="anexos">

                        <div className="row mt-3">

                          <div className="col-lg-3 col-sm-3 col-3">

                            <div className="form-group">
                              <label>Bilhete/Passaporte</label>

                              <input type="file" id='input-doc-bilhete' className='d-none' onChange={handleFileChangeBilhete} />

                              <div className="image-uploads">
                                {doc_bilhete ? (
                                  <>
                                    <img width={150} height={150}
                                      src={URL.createObjectURL(doc_bilhete)}
                                      alt="ficheiro carregado"
                                    />
                                    <h6 onClick={triggerFileChangeDocBilhete}><label>Carregar Bilhete o Passaporte</label></h6>
                                  </>
                                ) : (
                                  <h6 onClick={triggerFileChangeDocBilhete}><label>Carregar Bilhete Ou Passaporte</label></h6>
                                )}
                              </div>

                              
                              { inputs?.doc_bilhete ? <><a href={url_root+'/api/ler_anexo/'+inputs?.doc_bilhete} target="_blank" rel="noreferrer">Abrir o ficheiro</a></> : <></>}
                              

                            </div>

                          </div>

                          <div className="col-lg-3 col-sm-3 col-3">

                            <div className="form-group">
                              <label>CV ( Curriculum Vitae )</label>

                              <input type="file" id='input-doc-cv' className='d-none' onChange={handleFileChangeCV} />

                              <div className="image-uploads">
                                {doc_cv ? (
                                  <>
                                    <img width={150} height={150}
                                      src={URL.createObjectURL(doc_cv)}
                                      alt="CV carregado" className="border border-2"
                                    />
                                    <h6 onClick={triggerFileChangeDocCV}><label>Carregar CV</label></h6>
                                  </>
                                ) : (
                                  <h6 onClick={triggerFileChangeDocCV}><label>Carregar CV</label></h6>
                                )}
                              </div>

                              { inputs?.doc_cv ? <><a href={url_root+'/api/ler_anexo/'+inputs?.doc_cv} target="_blank" rel="noreferrer">Abrir o ficheiro</a></> : <></>}


                            </div>

                          </div>

                          <div className="col-lg-3 col-sm-3 col-3">

                            <div className="form-group">
                              <label>Declarações/Certificados</label>

                              <input type="file" id='input-doc-academico' className='d-none' onChange={handleFileChangeAcademico} />

                              <div className="image-uploads">
                                {doc_academico ? (
                                  <>
                                    <img width={150} height={150}
                                      src={URL.createObjectURL(doc_academico)}
                                      alt="ficheiro carregado"
                                    />
                                    <h6 onClick={triggerFileChangeDocAcademico}><label>Carregar Certificados ou Declarações Acádemicas</label></h6>
                                  </>
                                ) : (
                                  <h6 onClick={triggerFileChangeDocAcademico}><label>Carregar Certificados ou Declarações Acádemicas</label></h6>
                                )}
                              </div>

                              { inputs?.doc_academico ? <><a href={url_root+'/api/ler_anexo/'+inputs?.doc_academico} target="_blank" rel="noreferrer">Abrir o ficheiro</a></> : <></>}


                            </div>

                          </div>

                          <div className="col-lg-3 col-sm-3 col-3">

                            <div className="form-group">
                              <label>Certificações</label>

                              <input type="file" id='input-doc-certificacao' className='d-none' onChange={handleFileChangeCertificacao} />

                              <div className="image-uploads">
                                {doc_certificacoes ? (
                                  <>
                                    <img width={150} height={150}
                                      src={URL.createObjectURL(doc_certificacoes)}
                                      alt="ficheiro carregado"
                                    />
                                    <h6 onClick={triggerFileChangeDocCertificacao}><label>Carregar Certificações</label></h6>
                                  </>
                                ) : (
                                  <h6 onClick={triggerFileChangeDocCertificacao}><label>Carregar Certificações</label></h6>
                                )}
                              </div>

                              { inputs?.doc_certificacoes ? <><a href={url_root+'/api/ler_anexo/'+inputs?.doc_certificacoes} target="_blank" rel="noreferrer">Abrir o ficheiro</a></> : <></>}


                            </div>

                          </div>

                        </div>


                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>



           
                <div className="row mb-4">
                  <div className="col-lg-6">
                    <a onClick={submitForm} className="btn btn-submit me-2">
                      {loading ? <Spinner /> : 'Salvar'}
                    </a>
                    <a onClick={navegarParaLista} className="btn btn-cancel">
                      Cancelar
                    </a>
                  </div>
                </div>


            <div className="row mt-3">

              {loading ? <Spinner /> : <>


                <table className="table table-bordered" width="100%">
                  <thead>
                    <tr>
                      <th colSpan="5">
                        FUNÇÕES DO CANDIDATO {inputs?.name?.toUpperCase()}
                      </th>
                      <th>
                        <a className="btn btn-danger"
                          onClick={desvincularFormadorfuncao}
                        >
                          <i className="fa fa-times"></i>
                          DESASSOCIAR FUNÇÃO
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          className="form-control-user"
                          checked={checkGeral}
                          onChange={handleCheckGeralChange2}
                        />
                      </th>
                      <th>Nº</th>
                      <th>Foto</th>
                      <th>Designação</th>
                      <th>Descrição</th>
                      <th>Operações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      funcaos?.map((cdata, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              className="form-control-user check-colaborador"
                              onChange={handleCheckBoxChange2} data-id={cdata?.funcao?.id}
                            />
                          </td>
                          <td>{index + 1}</td>

                          <td className="text-center">
                            <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata?.funcao?.imagem} alt="fotografia" />
                          </td>

                          <td>{cdata?.funcao?.designacao}</td>

                          <td>
                            {cdata?.funcao?.descricao}
                          </td>

                          <td>
                            <span className="icon" onClick={() => irParafuncao(cdata?.funcao?.id)}>
                              <i className="fa fa-pencil"></i>
                            </span>
                          </td>

                        </tr>))
                    }
                  </tbody>
                </table>


              </>}

            </div>
          </div>
        </div>

        {/* fim funcaos do formador */}




        {/* Add Event Modal */}
        <div id="add_funcao" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add funções</h5>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true"></span>
                </button>

              </div>

              <div className="modal-body">

                <form>

                  <div className="form-group">

                    <input className="form-control" type="text" placeholder="procurar..." />

                    <a className="btn btn-primary"
                      onClick={vincularFormadorfuncao}>
                      ADICIONAR SELECIONADAS
                    </a>

                  </div>

                  <div className="form-group">

                    <div className="table-responsive">
                      <table className="table table-bordered" id="dataTable" width="100%">
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                className="form-control-user"
                                id="checkgeral" name="chGeral"
                                checked={checkGeral}
                                onChange={handleCheckGeralChange}
                              />
                            </th>
                            <th>Nº</th>
                            <th>Foto</th>
                            <th>Designação</th>
                            <th>Descrição</th>
                            <th>Operações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            funcao?.map((cdata, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="form-control-user check-funcao"
                                    onChange={handleCheckBoxChange} data-id={cdata.id}
                                  />
                                </td>
                                <td>{index + 1}</td>
                                <td className="text-center">
                                  <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata.imagem} alt="Imagem" />
                                </td>
                                <td>{cdata.designacao}</td>
                                <td>
                                  {cdata.categoria}
                                </td>
                                <td>



                                  <span className="icon text-white-50" onClick={() => irParafuncao(cdata.id)}>
                                    <i className="fa fa-pencil"></i>
                                  </span>
                                </td>
                              </tr>))
                          }
                        </tbody>
                      </table>
                    </div>


                  </div>

                </form>

              </div>

            </div>
          </div>
        </div>
        {/* /Add Event Modal */}

      </div>

      <ToastContainer />

    </div>

  );
};

NewformadorEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired
};

export default NewformadorEdit;
