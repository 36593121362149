/* eslint-disable react/prop-types */
//import React from 'react';
import React, { useState, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import NovoAcesso from './novoacesso';
import AcessoLista from './acessolista';
import AcessoEdit from './acessoedit';
import PermissaoAcesso from './PermissaoAcesso';

import AuthUser from '../../components/AuthUser';


const AcessoIndex = ({ match }) => {

     // eslint-disable-next-line no-unused-vars
     const { verificarPermissao } = AuthUser();
     // eslint-disable-next-line no-unused-vars
     const [permissaoVer, setPermissaoVer] = useState(false);
     const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
     const [permissaoAtualizar, setPermissaoAtualizar] = useState(false);
     const [permissaoAtivar, setPermissaoAtivar] = useState(false);
     const [permissaoRemover, setPermissaoRemover] = useState(false);
 
     useEffect(() => {
 
         setPermissaoVer(verificarPermissao('ACESSO','ver'));
         setPermissaoAdicionar(verificarPermissao('ACESSO','adicionar'));
         setPermissaoAtualizar(verificarPermissao('ACESSO','atualizar'));
         setPermissaoAtivar(verificarPermissao('ACESSO','ativar'));
         setPermissaoRemover(verificarPermissao('ACESSO','remover'));
 
     },);
 
     return (
    <Switch>
        <Redirect exact from={`${match.url}/`}  render={(props) => <NovoAcesso {...props} permissaoAdicionar={permissaoAdicionar} />} />
        <Route path={`${match.url}/novoacesso`} render={(props) => <NovoAcesso {...props} permissaoAdicionar={permissaoAdicionar} />} />
        <Route path={`${match.url}/acessoLista`}  render={(props) => <AcessoLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />} />
        <Route path={`${match.url}/acessoedit/:id`} render={(props) => <AcessoEdit {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />} />
        <Route path={`${match.url}/permissao/:acesso_id`}  render={(props) => <PermissaoAcesso {...props} permissaoAtualizar={permissaoAtualizar} />} />
    </Switch>)
}

export default AcessoIndex