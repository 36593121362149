/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import PropTypes from 'prop-types';

const CursoEdit = ({ permissaoAtivar, permissaoAtualizar }) => {

  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [galeria, setGaleria] = useState(1);
  const [nivel, setNivel] = useState(1);
  const [loading, setLoading] = useState(false);

  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);

  const [inputs, setInputs] = useState([]);

  /* TABELA MODAL FORMADORES */

  const [colaborador, setColaborador] = useState([]);
  const [formadores, setFormadores] = useState([]);

  // State to track whether the "checkgeral" checkbox is checked
  const [checkGeral, setCheckGeral] = useState(false);
  const [colaboradoresSelecionados, setColaboradoresSelecionados] = useState([]);


  // Function to handle the "checkgeral" checkbox change
  const handleCheckGeralChange = () => {
    // Toggle the checked state
    setCheckGeral(!checkGeral);

    // Get all the "check-colaborador" checkboxes
    const checkboxes = document.querySelectorAll(".check-colaborador");

    // Create a new array to store the selected collaborator IDs
    const selectedColaboradores = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !checkGeral;
      if (checkbox.checked) {
        // Add the ID to the selectedColaboradores array
        selectedColaboradores.push(checkbox.dataset.id);
      }
    });

    // Update the state with the selected collaborator IDs
    setColaboradoresSelecionados(selectedColaboradores);

  };


  // Function to handle the "checkBoxes from table" checkbox change
  const handleCheckBoxChange = () => {

    // Get all the "check-colaborador" checkboxes
    const checkboxes = document.querySelectorAll(".check-colaborador");

    // Create a new array to store the selected collaborator IDs
    const selectedColaboradores = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {

      if (checkbox.checked) {
        // Add the ID to the selectedColaboradores array
        const colaboradorId = checkbox.dataset.id;
        console.log('Colaborador ID selecionado:', colaboradorId);
        selectedColaboradores.push(colaboradorId);
      }
    });

    // Update the state with the selected collaborator IDs
    setColaboradoresSelecionados(selectedColaboradores);

  }

  const vincularFormadorCurso = async () => {

    if (permissaoAtualizar) {
      // Exibe a mensagem de confirmação

      if(colaboradoresSelecionados.length > 0){

      Swal.fire({
        title: "Tem certeza ?",
        text: "Pretende associar os formadores selecionados ao curso [ " + inputs.designacao + " ] ?.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, associar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post('/curso/formadores/associar', {
              colaboradoresSelecionados, id
            });

            getFormadores();

            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error('Ocorreu um erro ao associar formadores ao curso. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

          }
        }
      });

    } else { 

      toast.error('Tem de selecionar pelo menos um formador', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

     }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const desvincularFormadorCurso = async () => {

    if (permissaoAtualizar) {
      // Exibe a mensagem de confirmação

      if(colaboradoresSelecionados.length > 0){

      Swal.fire({
        title: "Tem certeza ?",
        text: "Pretende Desassociar os formadores selecionados do curso [ " + inputs.designacao + " ] ?.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, associar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post('/curso/formadores/desassociar', {
              colaboradoresSelecionados, id
            });

            getFormadores();

            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error('Ocorreu um erro ao associar formadores ao curso. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

          }
        }
      });

    } else { 

      toast.error('Tem de selecionar pelo menos um formador', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

     }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  /* FIM CONSTANTES TABELA MODAL FORMADORES */

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push('/dream-pos/curso/cursolista');
  };

  const irParaFormador = (id) => {

    const element = document.querySelector('.btn-close');

    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }

    history.push('/dream-pos/users/newformadoredit/' + id);

  }

  useEffect(() => {
    getCurso();

  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };


  const getCurso = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/curso/' + id);

      console.log("Carregado...");
      console.log(res.data);

      setInputs(res.data.curso);

      setNivel(res.data.curso.nivel);

      setimagemPreview(res.data.curso.imagem);

      const resultado = await http.get('/utilizadores/formadores');
      setColaborador(resultado.data.utilizadores);

      getFormadores();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getFormadores = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/formadores/curso/' + id);

      setFormadores(res.data.formadores);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {

    if (permissaoAtivar) {

      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/curso/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getCurso();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de cursos após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do curso. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSelectGaleriaChange = (valor) => {
    setGaleria(valor);
  }

  const handleSelectNivelChange = (valor) => {
    setNivel(valor);
  }

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem').src = newFileURL;

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (permissaoAtualizar) {

      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs.designacao.length < 2) {
          setError('A designação deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('A designação deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await updatecurso();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {

          console.log("Erro:");
          console.log(error);

          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }



  };

  const updatecurso = async () => {
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('designacao', inputs.designacao);
      formData.append('nivel', nivel);
      formData.append('categoria', inputs.categoria);
      formData.append('duracao', inputs.duracao);
      formData.append('idioma', inputs.idioma);
      formData.append('preco', inputs.preco);
      formData.append('ordem', inputs.ordem);
      formData.append('descricao_curta', inputs.descricao_curta);
      formData.append('especificacao', inputs.especificacao);
      formData.append('requisitos', inputs.requisitos);
      formData.append('galeria', galeria);
      formData.append('imagem', imagem);

      const response = await http.post('/curso/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de cursos após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          }
        }
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o curso. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-book border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de registo de cursos</h4>
              <h6>Editar curso</h6>
            </div>
          </div>
          <div className="page-btn">

            <Link to="/dream-pos/curso/novocurso" className="btn btn-added">
              <i className="fa fa-plus"></i>&nbsp;
              Adicionar curso
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Designação</label>
                    <input type="text" name="designacao" id="designacao" value={inputs.designacao} onChange={handleInputsValueChanged} />
                  </div>
                  <div className="form-group">
                    <label>Categoria</label>
                    <input id="categoria" name="categoria" type="text" value={inputs.categoria} onChange={handleInputsValueChanged} />
                  </div>
                  <div className="form-group">
                    <label>Idioma</label>
                    <input id="idioma" name="idioma" type="text" value={inputs.idioma} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>No slide do website ?</label>

                    <select className="form-control select" name="galeria" onChange={e => handleSelectGaleriaChange(e.target.value)} value={galeria}>

                      <option value="1" selected={inputs.galeria == 1}>
                        Aparece
                      </option>
                      <option value="2" selected={inputs.galeria == 2}>
                        Não aparece
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Descrição curta</label>
                    <textarea className="form-control" name="descricao_curta" value={inputs.descricao_curta} onChange={handleInputsValueChanged}></textarea>
                  </div>

                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() => handleAtivarDesativar(id, !inputs.estado)}
                        name={`estado_${id}`}
                      />
                      <label htmlFor={`user${id}`} className="checktoggle">

                      </label>
                    </div>

                  </div>


                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>


                </div>
                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Nível</label>

                    <select className="form-control select" name="nivel" onChange={e => handleSelectNivelChange(e.target.value)} value={nivel} >
                      <option value="1" selected={inputs.nivel == 1}>
                        Básico
                      </option>
                      <option value="2" selected={inputs.nivel == 2}>
                        Médio
                      </option>
                      <option value="3" selected={inputs.nivel == 3}>
                        Avançado
                      </option>
                      <option value="4" selected={inputs.nivel == 4}>
                        Expert
                      </option>

                    </select>

                  </div>


                  <div className="form-group">
                    <label>Duração ( horas )</label>
                    <input id="duracao" name="duracao" type="text" value={inputs.duracao} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Preço</label>
                    <input id="preco" name="preco" type="text" value={inputs.preco} onChange={handleInputsValueChanged} />
                  </div>


                  <div className="form-group">
                    <label>Ordem ( visualização )</label>
                    <input id="ordem" name="ordem" type="text" value={inputs.ordem} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Espeficicações</label>
                    <textarea className="form-control" name="especificacao" value={inputs.especificacao} onChange={handleInputsValueChanged}></textarea>
                  </div>

                  <div className="form-group">
                    <label>Requisitos</label>
                    <textarea className="form-control" name="requisitos" value={inputs.requisitos} onChange={handleInputsValueChanged}></textarea>
                  </div>


                </div>


                {/* Restante do código */}
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>imagem</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img id="imagem"
                              src={imagemPreview ? url_root + "/storage/" + imagemPreview : URL.createObjectURL(imagem)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}
                      </div>
                    </div>
                  </div>


                </div>
                {/* Restante do código */}
                <div className="col-lg-4">

                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>

                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>



                </div>

                <div className="col-lg-4">

                  <a
                    href="#"
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_formador">

                    <i className="fa fa-user"></i> Adicionar formador

                  </a>

                </div>


              </div>
            </form>

          </div>
        </div>
        {/* /add */}


        {/* INICIO SUB-áreas */}

        <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">



                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">




              {loading ? <Spinner /> : <>


                <table className="table table-bordered" width="100%">
                  <thead>
                    <tr>
                      <th colSpan="6">
                        FORMADORES DO CURSO DE {inputs.designacao}
                      </th>
                      <th>
                        <a className="btn btn-danger"
                        onClick={desvincularFormadorCurso}
                        >
                          <i className="fa fa-times"></i>
                          DESASSOCIAR FORMADORES
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          className="form-control-user"
                          checked={checkGeral}
                                onChange={handleCheckGeralChange}
                        />
                      </th>
                      <th>Nº</th>
                      <th>Foto</th>
                      <th>Nome</th>
                      <th>Tel</th>
                      <th>Email</th>
                      <th>Operações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      formadores?.map((cdata, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              className="form-control-user check-colaborador"
                              onChange={handleCheckBoxChange} data-id={cdata.formador.id}
                            />
                          </td>
                          <td>{index + 1}</td>
                          <td className="text-center">
                            <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata.formador.fotografia} alt="fotografia" />
                          </td>
                          <td>{cdata.formador.name}</td>
                          <td>
                            {cdata.formador.telefone}
                          </td>
                          <td>
                            {cdata.formador.email}
                          </td>
                          <td>
                            <span className="icon text-white-50" onClick={() => irParaFormador(cdata.formador.id)}>
                              <i className="fa fa-pencil"></i>
                            </span>
                          </td>
                        </tr>))
                    }
                  </tbody>
                </table>


              </>}




            </div>
          </div>
        </div>

        {/* FIM SUB-áreas */}



        {/* Add Event Modal */}
        <div id="add_formador" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Formador</h5>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true"></span>
                </button>

              </div>

              <div className="modal-body">

                <form>

                  <div className="form-group">

                    <input className="form-control" type="text" placeholder="procurar..." />

                    <a className="btn btn-primary"
                      onClick={vincularFormadorCurso}>
                      ADICIONAR SELECIONADOS
                    </a>

                  </div>

                  <div className="form-group">

                    <div className="table-responsive">
                      <table className="table table-dark table-bordered" id="dataTable" width="100%">
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                className="form-control-user"
                                id="checkgeral" name="chGeral"
                                checked={checkGeral}
                                onChange={handleCheckGeralChange}
                              />
                            </th>
                            <th>Nº</th>
                            <th>Foto</th>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Operações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            colaborador?.map((cdata, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="form-control-user check-colaborador"
                                    onChange={handleCheckBoxChange} data-id={cdata.id}
                                  />
                                </td>
                                <td>{index + 1}</td>
                                <td className="text-center">
                                  <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata.fotografia} alt="fotografia" />
                                </td>
                                <td>{cdata.name}</td>
                                <td>
                                  {cdata.telefone}<br />
                                  {cdata.email}
                                </td>
                                <td>
                                  <span className="icon text-white-50" onClick={() => irParaFormador(cdata.id)}>
                                    <i className="fa fa-pencil"></i>
                                  </span>
                                </td>
                              </tr>))
                          }
                        </tbody>
                      </table>
                    </div>


                  </div>

                </form>

              </div>

            </div>
          </div>
        </div>
        {/* /Add Event Modal */}

      </div>

      <ToastContainer />

    </div>
  );
};

CursoEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
};

export default CursoEdit;
