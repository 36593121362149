/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop"
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

//import formatNumber from 'format-number';

import { useHistory } from 'react-router-dom';

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";

import { format } from 'date-fns';

import generatePDF from "../../components/export/generatePDF";
import generateExcel from "../../components/export/generateExcel";

import {
  PlusIcon,
  search_whites,
} from "../../EntryFile/imagePath";

import PropTypes from 'prop-types';

const InscricaoLista = ({ permissaoVer, permissaoAtivar, permissaoRemover }) => {

  const history = useHistory();

  const { http, url_root, logoTipo, user_permissoes, verificarPermissao } = AuthUser();
  const [inputfilter, setInputfilter] = useState(false);

  const [Inscricoes_turma, setInscricoesTurma] = useState([]);
  const [formandos, setFormandos] = useState([]);
  const [cursos, setcurso] = useState([]);
  const [turmas, setTurmas] = useState([1, 2]);

  const [turma, setTurma] = useState([]);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ficheiroNome, setFicheiroNome] = useState('');

  const [botaoAtivo, setBotaoAtivo] = useState(null);

  const [titulo, setTitulo] = useState('');
  const [estado, setEstado] = useState(1);
  const [descricao, setDescricao] = useState('');

  const [emailFilter, setEmailFilter] = useState('');
  const [telefoneFilter, setTelefoneFilter] = useState('');
  const [nifFilter, setNifFilter] = useState('');

  const [nomeFilter, setNomeFilter] = useState('');
  const [curso_selecionado, setCursoSelecionado] = useState(-1);
  const [data1Filter, setData1Filter] = useState('');
  const [data2Filter, setData2Filter] = useState('');
  const [modoFilter, setModoFilter] = useState(-1);
  const [frequenciaFilter, setFrequenciaFilter] = useState(-1);
  const [periodoFilter, setPeriodoFilter] = useState(-1);
  const [estadoFilter, setEstadoFilter] = useState(-1);

  /* Formando novo */
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [tipo_pagamento, setPagamento] = useState(2);
  const [telefone, setTelefone] = useState('');
  const [data_nasc, setDataNasc] = useState(new Date());
  const [data_insc, setDataInsc] = useState(new Date());
  const [data_insc2, setDataInsc2] = useState(new Date());
  const [data_insc3, setDataInsc3] = useState(new Date());
  const [genero, setGenero] = useState(1);
  const [nif, setNIF] = useState('');
  const [valor, setValor] = useState(0);
  const [valor2, setValor2] = useState(0);
  const [valor3, setValor3] = useState(0);
  const [observacao, setObservacao] = useState('');
  const [observacao2, setObservacao2] = useState('');
  const [observacao3, setObservacao3] = useState('');
  /* Formando novo */

  const [tipoPagamento, setTipoPagamento] = useState(1);

  const [imagem, setSelectedFile] = useState(null);
  const [imagem2, setSelectedFile2] = useState(null);//comprovativo de pagamento

  const [imagem20, setSelectedFile20] = useState(null);//comprovativo de pagamento 2
  const [imagem200, setSelectedFile200] = useState(null);//comprovativo de pagamento 2
  const [imagem3, setImagem3] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);
  const [imagemPreview2, setimagemPreview2] = useState(null);
  const [imagemPreview20, setimagemPreview20] = useState(null);
  const [imagemPreview200, setimagemPreview200] = useState(null);


  const [formandos_lista, setFormandoLista] = useState([]);

  const [formandoSelecionado, setFormandoSelecionado] = useState(null);

  // State to track whether the "checkgeral" checkbox is checked
  const [checkGeral, setCheckGeral] = useState(false);
  const [InscricoesSelecionados, setInscricoesSelecionados] = useState([]);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedInscricoesIds, setselectedInscricoesIds] = useState([]);

  useEffect(() => {
    if (permissaoVer) {
      const fetchData = async () => {
        try {
          // Chama a função assíncrona getInscricoes()

          setTitulo('INSCRIÇÕES');
          setDescricao('RECENTES');

          await getcursos();
          await getFormandos(1);
          await getTurmas();

        } catch (error) {
          console.error('Erro ao obter cursos e formandos:', error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();
    }
  }, [permissaoVer]);

  const irParaFormando = (id) => {

    console.log(document.querySelector('.btn-close'));

    const element1 = document.querySelector('.btn-close')[0];
    if (element1) {
      element1.click();
    }

    const element2 = document.querySelector('.btn-close')[1];
    if (element2) {
      element2.click();
    }

    const element3 = document.querySelector('.btn-close')[3];
    if (element3) {
      element3.click();
    }

    const element4 = document.querySelector('.btn-close')[4];
    if (element4) {
      element4.click();
    }

    history.push('/dream-pos/users/newformandoedit/' + id);

  }

  const irParaTurma = (id) => {

    const element1 = document.querySelector('.btn-close')[0];
    if (element1) {
      element1.click();
    }

    const element2 = document.querySelector('.btn-close')[1];
    if (element2) {
      element2.click();
    }

    const element3 = document.querySelector('.btn-close')[3];
    if (element3) {
      element3.click();
    }

    const element4 = document.querySelector('.btn-close')[4];
    if (element4) {
      element4.click();
    }

    history.push('/dream-pos/turma/turmaedit/' + id);
  }

  // Function to handle the "checkgeral" checkbox change
  const handleCheckGeralChange = () => {
    // Toggle the checked state
    setCheckGeral(!checkGeral);

    // Get all the "check-Inscricao" checkboxes
    const checkboxes = document.querySelectorAll(".check-Inscricao");

    // Create a new array to store the selected collaborator IDs
    const selectedInscricoes = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !checkGeral;
      if (checkbox.checked) {
        // Add the ID to the selectedInscricoes array
        selectedInscricoes.push(checkbox.dataset.id);
      }
    });

    // Update the state with the selected collaborator IDs
    setInscricoesSelecionados(selectedInscricoes);

  };

  // Function to handle the "checkBoxes from table" checkbox change
  const handleCheckBoxChange = () => {

    // Get all the "check-Inscricao" checkboxes
    const checkboxes = document.querySelectorAll(".check-Inscricao");

    // Create a new array to store the selected collaborator IDs
    const selectedInscricoes = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {

      if (checkbox.checked) {
        // Add the ID to the selectedInscricoes array
        const InscricaoId = checkbox.dataset.id;
        console.log('Inscricao ID selecionado:', InscricaoId);
        selectedInscricoes.push(InscricaoId);
      }
    });

    // Update the state with the selected collaborator IDs
    setInscricoesSelecionados(selectedInscricoes);

  }

  const getFormandos = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/inscricoes/');

      setFormandos(res.data.formandos);

      //getInscricoes();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getcursos = async () => {

    try {

      const res = await http.get('/cursos');

      setcurso(res.data.cursos);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('turma não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getTurmas = async () => {

    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/turmas_dispniveis');

      setTurmas(res.data.turmas);

      setLoading(false);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('user não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const formandoDaInscricao = (formando_id) => {

    const formandoEncontrado = formandos.find((lista) => lista.id == formando_id);

    // Verifique se o objeto foi encontrado
    if (formandoEncontrado != undefined) {
      return formandoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  const CursoDaTurma = (curso_id) => {

    const cursoEncontrado = cursos.find((lista) => lista.id == curso_id);

    // Verifique se o objeto foi encontrado
    if (cursoEncontrado != undefined) {
      return cursoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  const getInscricoes = async ($estado) => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const response = await http.get('Inscricoes');

      console.log(response.data);
      setInscricoesTurma(response.data.Inscricoes);

      setLoading(false);


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleFilterSubmit = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append('curso_id', curso_selecionado);
      formData.append('nome_formando', nomeFilter);
      formData.append('data1', data1Filter);
      formData.append('data2', data2Filter);

      formData.append('modo', modoFilter);
      formData.append('sabados', frequenciaFilter);
      formData.append('periodo', periodoFilter);

      console.log('curso_id', curso_selecionado);
      console.log('nome_formando', nomeFilter);
      console.log('data1', data1Filter);
      console.log('data2', data2Filter);

      console.log('modo', modoFilter);
      console.log('sabados', frequenciaFilter);
      console.log('periodo', periodoFilter);

      const response = await http.post('/inscricoes/pesquisar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setTitulo('Inscricoes');
      setDescricao('PESQUISA');

      //console.log(response.data.turmas);
      setFormandos(response.data.inscricoes);

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };

  const handlePesquisarFormandoSubmit = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append('nome', nomeFilter);
      formData.append('telefone', telefoneFilter);
      formData.append('email', emailFilter);
      formData.append('nif', nifFilter);

      const response = await http.post('/utilizador/pesquisar_formando_1_termo', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      //console.log(response.data.users);

      // Atualize a lista de utilizadores após resetar a senha
      setFormandoLista(response.data.formandos);

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };


  const handleChechboxSelectionIds = (selectedRowKeys) => {
    setselectedInscricoesIds(selectedRowKeys);
  };

  const handleEliminarMultiplos = async () => {

    if (permissaoRemover) {

      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer eliminar ?",
        text: "Acção de eliminar Inscricoes é irreversivel, pretende continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post('/Inscricoes/eliminar-multiple', {
              InscricoesSelecionados,
            });

            getInscricoes();

            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error('Ocorreu um erro ao eliminar os usuários. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });


          }
        }
      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };

  const handleSelectedItemChange = async (newState) => {

    if (permissaoAtivar) {

      try {

        // Envie uma solicitação para a API para ativar/desativar os usuários selecionados
        const response = await http.post('/Inscricao/activate-multiple', {
          selectedInscricoesIds,
          newState,
        });

        getInscricoes();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado dos usuários. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } finally {
        setLoading(false);
        // Limpe o estado dos IDs dos usuários selecionados
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {

      setData1Filter(null);
      setData2Filter(null);
      setModoFilter('');
      setNomeFilter('');

    }

  };

  const renderOptioncursos = (cursos) => {
    return cursos?.map((curso) => (
      <React.Fragment key={curso.id}>
        <option value={curso.id}>{curso.designacao.toUpperCase()}</option>
      </React.Fragment>
    ));
  };

  const handleSelectCursoChange = (valor) => {

    setCursoSelecionado(valor);

  }

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const handleRemoverInscricao = (id) => {

    if (permissaoVer) {

      Swal.fire({
        title: "Tem a certeza que pretende remover esta inscrição ?",
        text: "Todos os pagamentos associados a esta inscrição tambem serão removidos, não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {

        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          removerInscricao(id);

        }

      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const removerInscricao = async (id) => {

    if (permissaoVer) {

      try {

        const formData = new FormData();
        formData.append('inscricao_id', id);

        const response = await http.post('/turma/remover/inscricao', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getFormandos();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de turmas após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao remover a inscrição. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };

  const desvincularFormandosTurma = async () => {

    if (permissaoVer) {
      // Exibe a mensagem de confirmação

      if (InscricoesSelecionados.length > 0) {

        const formandosSelecionados = InscricoesSelecionados;

        Swal.fire({
          title: "Tem certeza ?",
          text: "Pretende Desassociar os formandos selecionados da turma, isso removera tambem todos os pagamentos feitos relacionados a turma e estes formandos, quer continuar ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, continuar a remover!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Envie uma solicitação para a API para eliminar os usuários selecionados
              const response = await http.post('/turma/formandos/desassociar', {
                formandosSelecionados
              });

              getFormandos();

              toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } catch (error) {
              console.error(error);

              toast.error('Ocorreu um erro ao remover formandos da turma. Por favor, tente novamente mais tarde.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          }
        });

      } else {

        toast.error('Tem de selecionar pelo menos um formando', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  /*
  const formatarNumero = (numero, casasDecimais, separadorMilhares, separadorDecimal) => {
    const options = {
      round: casasDecimais,
      decimal: separadorDecimal,
      thousand: separadorMilhares,
    };
  
    return formatNumber(options)(numero);
  }; */

  const setValorPesquisa = (valor) => {

    setNomeFilter(valor);
    setTelefoneFilter(valor);
    setEmailFilter(valor);
    setNifFilter(valor);

  }

  const submitInscricaoFormandoExistenteForm = async () => {

    if (permissaoVer) {


      Swal.fire({
        title: "Confirmação ?",
        text: "Pretende registar esta inscrição ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, registar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (c) {

        if (c.isConfirmed) {

          try {

            if (formandoSelecionado == undefined || formandoSelecionado == null) {
              // Show a error toast
              toast.error('Deve selecionar primeiro um formando na tabela, faça pesquisa caso não consegue ver nenhum formando na tabela .', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }


            if (turma == null || turma?.length == 0) {
              // Show a error toast
              toast.error('Deve selecionar primeiro uma turma .', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }


            if (turma?.sala?.acentos <= turma?.formandos?.length) {
              // Show a error toast
              toast.error('Esta turma esta sem vagas de acordo ao numero de acentos disponiviveis na sala.', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }


            if (valor2 == 0 || valor2 == null || valor2 < 0)
              Swal.fire({
                title: "Valor de pagamento invalido?",
                text: "Tem a certeza que pretende registar esta inscrição sem pagamento ?",
                type: "warning",
                showCancelButton: !0,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, registar!",
                confirmButtonClass: "btn btn-primary",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: !1,
              }).then(async function (t) {

                if (t.isConfirmed) {

                  await inscrever_formando_existente();

                }

              });
            else await inscrever_formando_existente();


            // If the API call is successful, navigate to the dashboard
            //navigate('/dashboard');

          } catch (error) {
            if (error.response && error.response.status === 401) {


              // Show a error toast
              toast.error('Utilizador não autenticado.', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            } else {

              // Show a error toast
              toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          } finally {
            toggleLoading(false);
          }

        }


      });


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }



  };

  const submitInscricaoForm = async () => {

    if (permissaoVer) {

      try {


        if (turma == null || turma?.length == 0) {
          // Show a error toast
          toast.error('Deve selecionar primeiro uma turma .', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        // Basic form validation
        if (nome.length < 2) {

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        // Basic form validation
        if (nif.length < 5) {

          // Show a error toast
          toast.error('O NIF/BI/PASSPORT deve ter pelo menos cinco (5) caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        // Basic form validation
        if (telefone.length < 9) {

          // Show a error toast
          toast.error('O telefone deve ter pelo menos digitos (9) caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (!validateEmail(email)) {
          // Show a error toast
          toast.error('O email deve ser válido.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (calcularIdade(data_nasc) < 5) {
          // Show a error toast
          toast.error('A idade correspondenrte a esta data a inferior a 5 anos .', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (valor == 0 || valor == null || valor < 0)
          Swal.fire({
            title: "Valor de pagamento invalido?",
            text: "Tem a certeza que pretende registar esta inscrição sem pagamento ?",
            type: "warning",
            showCancelButton: !0,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, registar!",
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1,
          }).then(async function (t) {

            if (t.isConfirmed) {

              await inscrever_novo();

            }

          });
        else await inscrever_novo();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {


          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {




          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }



  };

  const inscrever_novo = async () => {

    try {

      const formData = new FormData();

      setPagamento(turma?.paga_coletivo);

      formData.append('turma_id', turma?.id);
      formData.append('tipo_pagamento', tipo_pagamento);
      formData.append('name', nome);
      formData.append('email', email);
      formData.append('nif', nif);
      formData.append('telefone', telefone);
      formData.append('data_nasc', data_nasc);
      formData.append('data_inscricao', data_insc);
      formData.append('genero', genero);
      formData.append('observacao', observacao);
      formData.append('comprovativo', imagem2);
      formData.append('valor', valor);
      formData.append('tipo_pagamento', tipoPagamento);

      formData.append('dia', obterInfoNaData(data_insc, 3));
      formData.append('mes', 1 + obterInfoNaData(data_insc, 2));
      formData.append('ano', obterInfoNaData(data_insc, 1));

      setLoading(true);

      const response = await http.post('/turma/formando/novo', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setNome('');
      setEmail('');
      setNIF('');
      setTelefone('')
      setDataNasc('');
      setDataInsc('');
      setObservacao('');
      setValor(0);
      setimagemPreview2('');

      getFormandos();
      getTurmas();

      setLoading(false);

      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {




          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  function calcularIdade(dataNascimento) {

    const hoje = new Date();
    const nascimento = new Date(dataNascimento);

    let idade = hoje.getFullYear() - nascimento.getFullYear();
    const mesAtual = hoje.getMonth();
    const mesNascimento = nascimento.getMonth();

    if (mesAtual < mesNascimento || (mesAtual === mesNascimento && hoje.getDate() < nascimento.getDate())) {
      idade--;
    }

    return idade;
  }

  const inscrever_formando_existente = async () => {

    try {

      const formData = new FormData();

      setPagamento(turma?.paga_coletivo);

      formData.append('formando_id', formandoSelecionado.id);
      formData.append('turma_id', turma?.id);
      formData.append('tipo_pagamento', tipo_pagamento);

      formData.append('observacao', observacao2);
      formData.append('comprovativo', imagem20);
      formData.append('valor', valor2);
      formData.append('tipo_pagamento', tipoPagamento);

      formData.append('data_inscricao', data_insc2);

      formData.append('dia', obterInfoNaData(data_insc2, 3));
      formData.append('mes', 1 + obterInfoNaData(data_insc2, 2));
      formData.append('ano', obterInfoNaData(data_insc2, 1));

      setLoading(true);

      const response = await http.post('/turma/formando/existente', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });


      setObservacao2('');
      setValor2(0);
      setimagemPreview20('');

      setFormandoSelecionado(null);

      getFormandos();
      getTurmas();

      setLoading(false);

      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {


          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {

      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  //tipo = 1 retorna o ano, tipo = 2 retorna o mês e tipo = 3 retorna o dia
  function obterInfoNaData(data, tipo) {

    const data1 = new Date(data);

    if (tipo == 1)
      return data1.getFullYear();
    else if (tipo == 2)
      return data1.getMonth();
    else
      return data1.getDate();
  }

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleFileChange20 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile20(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview20(newFileURL);

    // Restante do código
  };

  const handleFileChange200 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile200(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview200(newFileURL);

    // Restante do código
  };

  const handleSelecionarFormando = (formando) => {

    setFormandoSelecionado(null);

    if (encontrarFormandoPorId(formando.id)) {

      toast.error('Este formando ja esta adicionado a esta turma, não sera possivel adiciona-lo mais de uma vez.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } else {

      setFormandoSelecionado(formando);

      toast.success('formando selecionado.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    }

  }

  const encontrarFormandoPorId = (id) => {
    // Encontre o objeto com a ID correspondente
    const formandoEncontrado = turma?.formandos?.find((lista) => lista.formando_id == id);

    // Verifique se o objeto foi encontrado
    if (formandoEncontrado != undefined) {
      return formandoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }
  };

  const handleFileChange2 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile2(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview2(newFileURL);

    // Restante do código
  };

  const renderOptionTurmas = (turmas) => {
    return turmas?.map((turma) => (
      <React.Fragment key={turma.id}>

        <option value={turma.id}>

          {turma?.curso?.designacao.toUpperCase()} | {turma?.dataIni} - {turma?.dataFim} | {turma?.hora1?.substring(0, 5)} - {turma?.hora2?.substring(0, 5)} - {turma?.modo == 1 ? 'Presencial' : 'Online'} - {turma?.sabados == 2 ? 'Aos sabados' : 'Segunda á Sexta'} - Vagas {turma?.formandos?.length + ' / ' + turma?.sala?.acentos} - Acessibilidade {turma?.paga_coletivo == 1 ? ' Publico geral ' : ' Empresa ou grupo particular '}

        </option>

      </React.Fragment>
    ));
  };

  const handleSelectTurmaChange = (turma_id) => {

    const turmaSelecionada = getInfoDaTurma(turma_id);

    setTurma(turmaSelecionada);

    setFormandoSelecionado(null);

  }


  const getInfoDaTurma = (turma_id) => {

    const turmaEncontrada = turmas.find((lista) => lista.id == turma_id);

    // Verifique se o objeto foi encontrado
    if (turmaEncontrada != undefined) {
      return turmaEncontrada;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  const handleUnselectFormando = () => {

    setFormandoSelecionado(null);

  }

  if (permissaoVer)
    return (
      <>
        return (
        <>
          <div className="page-wrapper">
            <div className="content">
              <div className="page-header">
                <div className="titles">

                  <i alt="Image" className="header-image fa fa-tags border p-3 rounded-3"></i>

                  <div>
                    <h4>Gestão de inscrições</h4>
                    <h6>Lista de inscrições</h6>
                  </div>

                </div>
                <div className="page-btn">

                  <a href="#"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_formando">
                    <i className="fa fa-user-plus"></i>&nbsp;
                    Registar inscrição
                  </a>

                </div>
              </div>
              {/* /product list */}
              <div className="card">
                <div className="card-body">

                  <Tabletop inputfilter={inputfilter}
                    togglefilter={togglefilter}
                    handleSelectedItemChange={handleSelectedItemChange}
                    handleEliminarMultiplos={handleEliminarMultiplos}
                    generatePDF={generatePDF}
                    generateExcel={generateExcel}
                    logoTipo={logoTipo}
                    tableHeader={tableHeader}
                    tableData={tableData}
                    ficheiroNome={ficheiroNome}
                    handleFilterSubmit={handleFilterSubmit}
                  />

                  {/* /Filter */}
                  <div
                    className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                    id="filter_inputs"
                    style={{ display: inputfilter ? "block" : "none" }}
                  >
                    <div className="card-body pb-0">
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="form-group">
                            <select
                              name="curso_id"
                              onChange={(e) => handleSelectCursoChange(e.target.value)}
                              value={curso_selecionado}
                              className="form-control select"
                            >
                              <option value={-1}>Selecione o curso</option>
                              {renderOptioncursos(cursos)}

                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Nome do formando" value={nomeFilter}
                              onChange={(e) => setNomeFilter(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group" title="data 1">
                            <input type="date" className="form-control" placeholder="data1" value={data1Filter}
                              onChange={(e) => setData1Filter(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12" title="data 2">
                          <div className="form-group">
                            <input type="date" className="form-control" placeholder="data2" value={data2Filter}
                              onChange={(e) => setData2Filter(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={modoFilter} onChange={(e) => setModoFilter(e.target.value)}
                            >
                              <option value="-1">Todos modos</option>
                              <option value="1">Presencial</option>
                              <option value="2">Online</option>
                              <option value="3">Hibrido</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={frequenciaFilter} onChange={(e) => setFrequenciaFilter(e.target.value)}
                            >
                              <option value="-1">Todas as frequências</option>
                              <option value="1">Dias utéis</option>
                              <option value="2">Sábados</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={periodoFilter} onChange={(e) => setPeriodoFilter(e.target.value)}
                            >
                              <option value="-1">Todos os periodos</option>
                              <option value="1">Manhã</option>
                              <option value="2">Tarde</option>
                              <option value="3">Noite</option>
                            </select>
                          </div>
                        </div>



                        <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img src={search_whites} alt="img" onClick={handleFilterSubmit} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Filter */}
                  <div className="table-responsive">

                    {loading ? (<span>Carregando...</span> // Render the spinner component when loading is true
                    ) : (

                      <table className="table table-bordered table-responsive">
                        <thead>
                          <tr>
                            <th colSpan="8">

                              <a
                                className={'btn btn-light'}
                              >
                                <i className="fa fa-table"></i>&nbsp; {formandos.length}
                              </a>&nbsp;&nbsp;

                              INSCRIÇÕES [ {descricao} ]

                              <hr />

                            </th>
                            <th colSpan="3">

                              <a
                                className={'btn btn-danger'}
                                onClick={desvincularFormandosTurma}
                              >
                                <i className="fa fa-trash"></i>&nbsp; REMOVER INSCRIÇÃO
                              </a>

                              <hr />

                            </th>
                          </tr>
                          <tr>

                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            <th>
                              <input type="checkbox" id="checkgeral" name="chGeral"
                                checked={checkGeral}
                                onChange={handleCheckGeralChange} />
                            </th>
                            <th>Nº</th>
                            <th>Data / Atendido</th>
                            <th>Nome / BI</th>
                            <th>Tel/Email</th>
                            <th>Icon</th>
                            <th>Turma/Curso</th>
                            <th>Periodo</th>
                            <th>Assistencia</th>
                            <th>Dias</th>
                            <th>
                              Operações &nbsp;&nbsp;
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            formandos?.map((cdata, index) => (
                              <tr key={index} className={cdata.situacao == 1 ? '' : 'text-danger'} title="Desistente" >
                                <td>
                                  <input
                                    type="checkbox"
                                    className="check-Inscricao"
                                    onChange={handleCheckBoxChange} data-id={cdata.id}
                                  />
                                </td>
                                <td className={cdata.situacao == 1 ? '' : 'text-danger'} title="Desistente" >{index + 1}</td>

                                <td className={cdata.situacao == 1 ? '' : 'text-danger'} title="Desistente"  >{format(new Date(cdata.data_inscricao), 'dd/MM/yyyy')} <br></br> [ {cdata?.atendente?.name.toUpperCase()} ] </td>

                                <td className={cdata.situacao == 1 ? '' : 'text-danger'} title="Desistente" >{cdata.formando.name}<br></br>BI [ {cdata.formando.nif} ] &nbsp;&nbsp; <span className="icon" title="Ver info deste formando" onClick={() => irParaFormando(cdata.formando_id)}  >
                                  <i className="fa fa-pencil text-warning"></i>
                                </span></td>

                                <td className={cdata.situacao == 1 ? '' : 'text-danger'} title="Desistente" >
                                  {cdata.formando.telefone}<br></br>
                                  {cdata.formando.email}
                                </td>
                                <td className={cdata.situacao == 1 ? 'text-center' : 'text-dangertext-center'}>
                                  <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + CursoDaTurma(cdata?.turma?.curso_id)?.imagem} alt="fotografia" />
                                </td>
                                <td className={cdata.situacao == 1 ? '' : 'text-danger'} title="Desistente" >
                                  {CursoDaTurma(cdata?.turma?.curso_id)?.designacao.toUpperCase()} <br></br> {cdata?.turma?.dataIni} - {cdata?.turma?.dataFim} | {cdata?.turma?.hora1.substring(0, 5)} - {cdata?.turma?.hora2.substring(0, 5)}

                                  &nbsp;&nbsp;

                                  <span className="icon" title="Ver info desta turma" onClick={() => irParaTurma(cdata.turma_id)}  >
                                    <i className="fa fa-calendar text-warning"></i>
                                  </span>

                                </td>

                                <td className={cdata.situacao == 1 ? '' : 'text-danger'} title="Desistente">
                                  {cdata.turma.periodo == 1 ? 'Manhã' : cdata.turma.periodo == 2 ? 'Tarde' : 'Noite'}
                                </td>

                                <td className={cdata.situacao == 1 ? '' : 'text-danger'} title="Desistente">
                                  {cdata.turma.modo == 1 ? 'Presencial' : cdata.turma.modo == 2 ? 'Online' : 'Hibrido'}
                                </td>

                                <td className={cdata.situacao == 1 ? '' : 'text-danger'} title="Desistente">
                                  {cdata.turma.sabados == 2 ? 'Sábados' : 'Segunda á Sexta'}
                                </td>

                                <td>
                                  &nbsp;&nbsp;

                                  <span className="icon" title="Remover esta inscricão" onClick={() => handleRemoverInscricao(cdata.id)}  >
                                    <i className="fa fa-trash text-danger"></i>
                                  </span>

                                </td>
                              </tr>))
                          }
                        </tbody>
                      </table>

                    )}

                  </div>
                </div>
              </div>
              {/* /product list */}
            </div>
          </div>

          {
            turmas == null ? <span>Carregando...</span> : <>

              {/* Add Event Modal */}
              <div id="add_formando" className="modal modal-md  fade" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                  <div className="modal-content modal-lg modal-center">
                    <div className="modal-header">
                      <h5 className="modal-title">Inscrever Formando</h5>

                      <button
                        type="button"
                        className="btn btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true"></span>
                      </button>

                    </div>

                    <div className="modal-body">


                      <hr />

                      <form>

                        <div className="form-group">


                          <select className="form-control"
                            onChange={(e) => handleSelectTurmaChange(e.target.value)}>
                            <option value={-1}>Selecione a turma</option>
                            {renderOptionTurmas(turmas)}
                          </select>


                        </div>

                        <hr />

                        <div className="form-group">

                          <input className="form-control" type="text" onChange={e => setValorPesquisa(e.target.value)} placeholder="digite o nome | email | telefone | NIF a pesquisar e pressione enter..." onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handlePesquisarFormandoSubmit()
                            }
                          }} />

                          <div className="row mt-3">
                            <div className="col-lg-6"><a className="btn btn-primary"
                              onClick={submitInscricaoFormandoExistenteForm}>

                              {loading ? <Spinner /> : 'INSCREVER SELECIONADO NA TABELA'}

                            </a></div>
                            <div className="col-lg-6">
                              <a
                                href="#"
                                className="btn btn-warning"
                                data-bs-toggle="modal"
                                data-bs-target="#add_formando_novo">
                                <i className="fa fa-user"></i> REGISTAR NOVO FORMANDO
                              </a>
                            </div>
                          </div>


                        </div>


                        <div className="row">

                          <div className="col-lg-6">

                            <div className="form-group">
                              <label>VALOR PAGO NA INSCRIÇÃO</label>
                              <input type="number" name="valor2" id="valor2" className="form-control" value={valor2} onChange={e => setValor2(e.target.value)} />
                            </div>

                            <div className="form-group">
                              <label>TIPO</label>
                              <select className="form-control" name="genero" onChange={e => setTipoPagamento(e.target.value)} value={tipoPagamento}>
                                <option value={1}>Dinheiro</option>
                                <option value={2}>Transferência</option>
                                <option value={3}>TPA</option>
                                <option value={4}>Outro</option>
                              </select>
                            </div>


                            <div className="form-group">
                              <label>OBSERVAÇÃO</label>
                              <textarea className="form-control" name="observacao" value={observacao2} onChange={e => setObservacao2(e.target.value)} ></textarea>
                            </div>


                            <div className="form-group">

                              {formandoSelecionado != null ? (
                                <div className="row">

                                  <div className="col-lg-12 mb-2">
                                    <span className="btn btn-warning">
                                      SELECIONADO
                                    </span>
                                    <span className="btn btn-danger" onClick={() => handleUnselectFormando()}>
                                      X
                                    </span>
                                  </div>

                                  <div className="col-lg-3">
                                    <img className="img-profile rounded-circle" width="60" height="60" src={url_root + "/storage/" + formandoSelecionado.fotografia} alt="fotografia" />
                                  </div>
                                  <div className="col-lg-6">
                                    {formandoSelecionado.name} <br></br>
                                    {formandoSelecionado.telefone} <br></br>
                                    {formandoSelecionado.email} <br></br>

                                    <span className="icon" onClick={() => irParaFormando(formandoSelecionado?.id)} title="editar dados do formando" >
                                      <i className="fa fa-pencil"></i>
                                    </span>&nbsp;&nbsp;

                                  </div>
                                </div>) : <></>
                              }



                            </div>

                          </div>

                          <div className="col-lg-6">

                            <div className="form-group">
                              <label>DATA INSCRIÇÃO</label>
                              <input type="date" name="dataInsc2" id="dataInsc2" className="form-control" placeholder="" value={data_insc2} onChange={e => setDataInsc2(e.target.value)} />
                            </div>

                            <div className="form-group">
                              <label>Comprovativo de pagamento</label>
                              <div className="image-upload image-upload-new">
                                <input type="file" onChange={handleFileChange20} />
                                <div className="image-uploads">
                                  {imagemPreview20 ? (
                                    <>
                                      <img id="imagem2"
                                        src={imagemPreview20 ? imagemPreview20 : URL.createObjectURL(imagem20)}
                                        alt="Pré-visualização"
                                      />
                                      <h4>Carregar imagem de comprovativo</h4>
                                    </>
                                  ) : (

                                    <>
                                      <img id="imagem"
                                        alt="Pré-visualização"
                                      />
                                      <h4>Carregar imagem de comprovativo</h4>
                                    </>

                                  )}
                                </div>
                              </div>
                            </div>

                          </div>


                        </div>


                        {formandoSelecionado == null ? (

                          <div className="form-group">

                            <div className="table-responsive">
                              <table className="table table-bordered" id="dataTable" width="100%">
                                <thead>
                                  <tr>
                                    <th>Nº</th>
                                    <th>Foto</th>
                                    <th>Nome</th>
                                    <th>Tel/Email</th>
                                    <th>Operações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    formandos_lista?.map((cdata, index) => (
                                      <tr key={index} onClick={() => handleSelecionarFormando(cdata)}>

                                        <td>{index + 1}</td>
                                        <td className="text-center">
                                          <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata.fotografia} alt="fotografia" />
                                        </td>
                                        <td>{cdata.name}</td>
                                        <td>
                                          {cdata.telefone}<br />
                                          {cdata.email}
                                        </td>
                                        <td>
                                          <span className="icon btn btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close" onClick={() => irParaFormando(cdata.id)}>
                                            <i className="fa fa-pencil"></i>
                                          </span>
                                        </td>
                                      </tr>))
                                  }
                                </tbody>
                              </table>
                            </div>


                          </div>
                        ) : <></>

                        }



                      </form>

                    </div>

                  </div>
                </div>
              </div>
              {/* /Add Event Modal */}

            </>
          }

          {
            turmas == null ? <span>Carregando...</span> : <>

              {/* Add Event Modal */}
              <div id="add_formando_novo" className="modal modal-md  fade" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                  <div className="modal-content modal-lg modal-center">
                    <div className="modal-header">
                      <h5 className="modal-title">Inscrever Formando ( novo registo )</h5>

                      <button
                        type="button"
                        className="btn btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true"></span>
                      </button>

                    </div>

                    <div className="modal-body">

                      <div className="form-group">


                        <select className="form-control"
                          onChange={(e) => handleSelectTurmaChange(e.target.value)}>
                          <option value={-1}>Selecione a turma</option>
                          {renderOptionTurmas(turmas)}
                        </select>


                      </div>

                      <hr />

                      <form>



                        <div className="row">

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>NOME</label>
                              <input type="text" name="nome" id="nome" className="form-control" placeholder="Digite O Primeiro E o Ultimo Nome" value={nome} onChange={e => setNome(e.target.value)} />
                            </div>
                            <div className="form-group">
                              <label>NIF / BI / PASSAPORTE</label>
                              <input type="text" name="nif" id="nif" className="form-control" placeholder="" value={nif} onChange={e => setNIF(e.target.value)} />
                            </div>

                            <div className="form-group">
                              <label>DATA NASCIMENTO</label>
                              <input type="date" name="data_nasc" id="data_nasc" className="form-control" placeholder="" value={data_nasc} onChange={e => setDataNasc(e.target.value)} />
                            </div>

                            <div className="form-group">
                              <label>TELEFONE</label>
                              <input type="text" name="telefone" id="telefone" className="form-control" placeholder="" value={telefone} onChange={e => setTelefone(e.target.value)} />
                            </div>

                            <div className="form-group">
                              <label>VALOR PAGO NA INSCRIÇÃO</label>
                              <input type="number" name="valor" id="valor" className="form-control" value={valor} onChange={e => setValor(e.target.value)} />
                            </div>

                            <div className="form-group">
                              <label>TIPO</label>
                              <select className="form-control" name="genero" onChange={e => setTipoPagamento(e.target.value)} value={tipoPagamento}>
                                <option value={1}>Dinheiro</option>
                                <option value={2}>Transferência</option>
                                <option value={3}>TPA</option>
                                <option value={4}>Outro</option>
                              </select>
                            </div>


                            <div className="form-group">
                              <label>OBSERVAÇÃO</label>
                              <textarea className="form-control" name="observacao" value={observacao} onChange={e => setObservacao(e.target.value)} ></textarea>
                            </div>

                          </div>
                          <div className="col-lg-6">

                            <div className="form-group">
                              <label>GENERO</label>
                              <select className="form-control" name="genero" onChange={e => setGenero(e.target.value)} value={genero}>
                                <option value={1}>Feminino</option>
                                <option value={2}>Masculino</option>
                              </select>
                            </div>

                            <div className="form-group">
                              <label>E-MAIL</label>
                              <input type="email" name="email" id="email" className="form-control" placeholder="" value={email} onChange={e => setEmail(e.target.value)} />
                            </div>

                            <div className="form-group">
                              <label>DATA INSCRIÇÃO</label>
                              <input type="date" name="dataInsc" id="dataInsc" className="form-control" placeholder="" value={data_insc} onChange={e => setDataInsc(e.target.value)} />
                            </div>



                            <div className="form-group">
                              <label>Comprovativo de pagamento</label>
                              <div className="image-upload image-upload-new">
                                <input type="file" onChange={handleFileChange2} />
                                <div className="image-uploads">
                                  {imagemPreview2 ? (
                                    <>
                                      <img id="imagem2"
                                        src={imagemPreview2 ? imagemPreview2 : URL.createObjectURL(imagem2)}
                                        alt="Pré-visualização"
                                      />
                                      <h4>Carregar imagem de comprovativo</h4>
                                    </>
                                  ) : (

                                    <>
                                      <img id="imagem"
                                        alt="Pré-visualização"
                                      />
                                      <h4>Carregar imagem de comprovativo</h4>
                                    </>

                                  )}
                                </div>
                              </div>
                            </div>

                          </div>

                          <div className="col-lg-4">

                            <a onClick={submitInscricaoForm} className="btn btn-submit me-2">
                              {loading ? <Spinner /> : 'INSCREVER'}
                            </a>

                          </div>


                        </div>




                      </form>

                    </div>

                  </div>
                </div>
              </div>
              {/* /Add Event Modal */}

            </>
          }




          <ToastContainer />

        </></>
    );
  else
    return (<>
      <div className="page-wrapper cardhead">
        <div className="content ">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">SEM PERMISSÃO</h3>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"><i className="fa fa-times-circle text-danger"></i>&nbsp; OPERAÇÃO NÃO AUTORIZADA</h4>
                </div>
                <div className="card-body">
                  <p>
                    Esta operação não é autorizada para o seu nivel de acesso ao sistema, por favor contacte o administrador do sistema.
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>)

};

InscricaoLista.propTypes = {
  permissaoVer: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default InscricaoLista;
